import { Factory } from 'miragejs';

import { assert } from '@repo/qonto-mirage/utils/assert';
export default Factory.extend({
  amount: 500000,
  amountCurrency: 'EUR',
  createdAt: () => new Date('2021-08-07T12:24:22Z'),
  closureEffectiveOn: null,
  closureExpectedGain: null,
  closureInterestRate: null,
  durationInMonth: 6,
  expectedGain: 500,
  interestRate: 0.2,
  isCloseable: false,
  maturityOn: '2022-02-10',
  openedOn: '2021-08-10',
  sequentialId: i => i + 1,
  statementPdfUrl: null,
  status: 'active',

  afterCreate(model) {
    assert(
      model.organization,
      `Missing 'organization' relationship on 'savingsAccount:${model.id}'`
    );
  },
});
