import { Serializer } from 'miragejs';

import { underscore } from '@repo/qonto-mirage/utils/string';

export default class ReceivableInvoicesSharedOrganizationSerializer extends Serializer {
  attrs = ['legalName', 'legalNumber'];

  serializeIds = 'never';

  keyForAttribute(attr) {
    return underscore(attr);
  }

  _attrsForModel(model) {
    // add address
    let { address } = model;
    return {
      ...super._attrsForModel(model),
      address_line_1: address.firstLine,
      address_line_2: address.secondLine,
      address_zipcode: address.zipcode,
      address_city: address.city,
      address_country: address.country,
      id: model.id,
      legal_country: model.legalCountry,
    };
  }
}
