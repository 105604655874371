import { Factory } from 'miragejs';

import { assert } from '@repo/qonto-mirage/utils/assert';

export default Factory.extend({
  conditions: [
    {
      field: 'amount',
      operation: 'gte',
      value: {
        value: '0.00',
        currency: 'EUR',
      },
    },
  ],
  afterCreate(approvalWorkflowState) {
    assert(
      approvalWorkflowState.steps?.length > 0,
      `'Steps' should not be empty on 'approval-workflow-states:${approvalWorkflowState.id}'`
    );
  },
});
