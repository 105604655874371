import type { ReactElement, HTMLProps } from 'react';
import cx from 'clsx';
import {
  BadgeArrow,
  BadgeRocket,
  BadgeLock,
  BadgeEuro,
  BadgeCone,
  BadgeStars,
} from '../../assets/icons';
import styles from './badge.strict-module.css';

type BadgeType = 'activate' | 'tryForFree' | 'upgrade' | 'save' | 'notAvailable' | 'newFeature';

export interface BadgeProps extends HTMLProps<HTMLSpanElement> {
  type: BadgeType;
  text?: string;
  className?: string;
}

const BADGE_STYLE = {
  activate: {
    IconComponent: BadgeArrow,
    colorClass: styles.purple,
    alt: 'activate',
  },
  tryForFree: {
    IconComponent: BadgeRocket,
    colorClass: styles.mint,
    alt: 'try for free',
  },
  upgrade: {
    IconComponent: BadgeLock,
    colorClass: styles.peach,
    alt: 'upgrade',
  },
  save: { IconComponent: BadgeEuro, colorClass: styles.peach, alt: 'save' },
  notAvailable: {
    IconComponent: BadgeCone,
    colorClass: styles.gray,
    alt: 'notAvailable',
  },
  newFeature: {
    IconComponent: BadgeStars,
    colorClass: styles.yellow,
    alt: 'new feature',
  },
} as const;

export function Badge({ type, text, className, ...props }: BadgeProps): ReactElement {
  const { IconComponent, colorClass, alt } = BADGE_STYLE[type];
  const iconOnly = !text;

  return (
    <span
      className={cx(
        'caption',
        styles.badge,
        iconOnly && styles['icon-only'],
        colorClass,
        className
      )}
      data-test-badge={type}
      {...props}
    >
      <span
        className={cx(styles.icon, iconOnly && styles['no-margin'])}
        data-test-badge-icon={IconComponent.name}
      >
        <IconComponent aria-label={alt} role="img" />
      </span>
      {text}
    </span>
  );
}
