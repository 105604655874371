import { Factory } from 'miragejs';

import {
  CLIENT_KIND,
  FREQUENCY_UNIT,
  RECURRING_TYPES,
  SCHEDULE_TYPE,
  SDD_MANDATE_STATUS,
} from '@repo/qonto-mirage/constants/direct-debit-collections';
import { assert } from '@repo/qonto-mirage/utils/assert';

export default Factory.extend({
  sequentialId: i => i + 1,
  status: SDD_MANDATE_STATUS.APPROVED,
  unique_mandate_reference: i => `RUBE6989666666${i + 1}`,
  creditor_scheme_id: 'some-creditor-scheme-id',
  mandate_signature_date: '2024-04-02T07:42:42.698Z',
  type: 'core',
  recurrence_type: RECURRING_TYPES.RECURRING,
  link: 'some-link-url',
  client_iban: 'FR76300010079412effez23424',
  client_bic: 'GT7542',

  organization_snapshot: {
    name: 'Company name',
    zip_code: '75009',
  },
  payment_info: {
    first_payment: {
      collection_date: '2024-04-02',
      amount: {
        value: '1.00',
        currency: 'EUR',
      },
      reference: i => `Payment reference ${i + 1}`,
    },
    notify_client: true,
    schedule_custom_unit: FREQUENCY_UNIT.WEEKS,
    schedule_custom_interval: 0,
    schedule_total_collection_count: 0,
    schedule_type: SCHEDULE_TYPE.DEFAULT,
  },

  afterCreate(mandate) {
    assert(
      mandate.organization,
      `Missing 'organization' relationship on 'direct-debit-collection-mandate'`
    );

    assert(mandate.client, `Missing 'client' relationship on 'direct-debit-collection-mandate'`);

    mandate.update({
      client_snapshot: {
        id: mandate.client.id,
        name: mandate.client.name,
        kind: CLIENT_KIND.COMPANY,
        email: 'user@example.com',
        locale: 'en',
        address: '18 rue de Navarin',
        city: 'Paris',
        zip_code: '75009',
        province_code: null,
        country_code: 'FR',
      },
      organization_snapshot: {
        id: mandate.organization.id,
        name: mandate.organization.name || 'Croissant SAS',
        address_line_1: 'Rue La Fayette',
        address_line_2: '20B',
        address_line_3: 'Suite 10',
        city: 'Paris',
        zip_code: '75009',
        country: 'FR',
      },
    });
  },
});
