import { Factory } from 'miragejs';

import { assert } from '@repo/qonto-mirage/utils/assert';

export default Factory.extend({
  amount: 0,
  amountCurrency: 'EUR',
  emitterBic: 'QNTOFR21XYZ',
  emitterIban: 'FR6512739000501363946291J78',
  emitterName: 'John Doe',
  enrichmentData: () => {},
  errors: () => [],
  executionDate: () => new Date('2020-04-28T17:54:57Z'),
  reference: null,
  refundable: true,
  refundedAt: null,
  refundRequestedAt: null,
  sequentialId: i => i + 1,
  status: 'pending',

  afterCreate(model) {
    assert(model.bankAccount, `Missing 'bankAccount' relationship on 'direct-debit:${model.id}'`);

    assert(
      !model.organization && !model.organizationId,
      `You should not pass an 'organization' relationship on 'direct-debit:${model.id}'`
    );

    if (!model.slug) {
      let orgSlug = model.bankAccount.organization.slug;
      let slug = `${orgSlug}-direct-debit-${model.sequentialId}`;
      model.update({ slug });
    }
  },
});
