import { association, Factory } from 'miragejs';

import {
  DEFAULT_C_IMAGE_32,
  DEFAULT_C_IMAGE_64,
  QONTO_LOGO_32,
  QONTO_LOGO_64,
} from '@repo/qonto-mirage/constants/organization';
import { dasherize } from '@repo/qonto-mirage/utils/string';

const FAKE_NAMES = [
  'Croissant SAS',
  'Apple',
  'Google',
  'Netflix',
  'GitHub',
  'Facebook',
  'Chocolat SAS',
];

export default Factory.extend({
  accessDisabled: false,
  associationTypeformFilledAt: null,
  associationTypeformUrl: null,
  avatarThumbUrl: null,
  avatarUrl: null,
  checkSettings: () => ({}),
  companyCreationFromScratch: false,
  contactEmail: 'admin@croissant.biz',
  contractStatus: 'signed',
  createdAt: '2019-10-09T07:45:33.861Z',
  declaredNumberOfEmployeeRange: null,
  defaultAvatarThumbUrl: null,
  defaultAvatarUrl: null,
  depositCapitalFlowType: null,
  depositCapitalStatus: null,
  draft: false,
  features: () => [],
  fxStatus: 'fx_approved',
  kybStatus: 'accepted',
  legalCountry: 'FR',
  legalForm: 'SAS',
  legalName: null,
  legalNumber: '81948962600013',
  legalRegistrationDate: '2019-05-14',
  legalSector: null,
  legalShareCapital: 1930.43,
  legalTvaNumber: null,
  name: i => FAKE_NAMES[i % FAKE_NAMES.length],
  onboardingPartner: null,
  onboardingPartnerType: null,
  originalPricePlanCode: 'essential',
  providerSource: 'legalstart',
  providerSourceContactEmail: null,
  referralCode: '7IFQGkU',
  referralRewardAmountCents: 10000,
  referralRewardEligibilityMinimumTransactionsAmountCents: 5000,
  referralUrl: 'https://landing-staging.qonto.co/r/7IFQGkU',
  siren: '819489626',
  slug: null,
  status: 'activated',
  taxResidenceCountry: null,
  taxResidenceTaxIdentificationNumber: null,
  tradeName: null,
  // transferSettings is used to contain max_amount_without_attachment_cents
  // which is the attachment limit used across platform
  // we need to default it to an object since it should not throw an error when destructured
  transferSettings: () => ({}),
  // if `underIncorporation` is `true` the organization is available on the
  // company creation 🇩🇪 api, but not on the `api` api.
  underIncorporation: false,
  underRegistration: false,
  unlimited: true,
  unreferenced: false,
  vatRatesCategories: () => [0.0, 2.1, 5.5, 10.0, 20.0, -1.0],

  address: association(),

  afterCreate(model) {
    if (!model.legalName) {
      model.update({ legalName: model.name });
    }

    if (!model.tradeName) {
      model.update({ tradeName: model.legalName });
    }

    if (!model.slug) {
      let slug = `${dasherize(model.name)}-${model.id.slice(0, 4)}`;
      model.update({ slug });
    }
    if (!model.avatarThumbUrl) {
      let avatarThumbUrl = QONTO_LOGO_32;
      model.update({ avatarThumbUrl });
    }
    if (!model.avatarUrl) {
      let avatarUrl = QONTO_LOGO_64;
      model.update({ avatarUrl });
    }
    if (!model.defaultAvatarThumbUrl) {
      let defaultAvatarThumbUrl = DEFAULT_C_IMAGE_32;
      model.update({ defaultAvatarThumbUrl });
    }
    if (!model.defaultAvatarUrl) {
      let defaultAvatarUrl = DEFAULT_C_IMAGE_64;
      model.update({ defaultAvatarUrl });
    }
  },
});
