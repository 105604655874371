import { Factory } from 'miragejs';

import { assert } from '@repo/qonto-mirage/utils/assert';

export default Factory.extend({
  createdAt: '2020-07-24T12:34:56Z',
  externalService: null,
  params: () => {},

  afterCreate(connection) {
    assert(
      connection.externalService,
      `Missing 'externalService' attribute on 'hub-connection:${connection.id}'`
    );

    assert(
      connection.organization,
      `Missing 'organization' relationship on 'hub-connection:${connection.id}'`
    );
  },
});
