import React from 'react';
import { Button, type BaseButtonProps } from '@repo/design-system-kit';
import { type ButtonProps as AriaButtonProps } from 'react-aria-components';
import type { CustomPricePlanProps } from '../custom-price-plan/custom-price-plan';
import type { MonthlyProps, AnnualProps } from './price-plan';
import { PricePlansContainer } from './price-plans-container';

interface MainButtonBridge {
  text: string;
  buttonProps: BaseButtonProps & AriaButtonProps;
}

type MonthlyPropsBridge = Omit<MonthlyProps, 'descriptionCta' | 'mainButton'> & {
  descriptionCta: {
    text: string;
    onPress: () => void;
  };
  mainButton: MainButtonBridge;
};

type AnnualPropsBridge = Omit<AnnualProps, 'mainButton'> & {
  mainButton: MainButtonBridge;
};

type PricePlansBridgeProps = (MonthlyPropsBridge | AnnualPropsBridge)[];

type CustomPricePlanBridgeProps = Omit<CustomPricePlanProps, 'mainButton'> & {
  mainButton: MainButtonBridge;
};

interface MainButtonStateProps {
  selectedPricePlanTitle: string;
  isLoading: boolean;
}

export interface PricePlansContainerProps {
  pricePlans: PricePlansBridgeProps;
  customPricePlan?: CustomPricePlanBridgeProps;
  // Handling reactivity nested deep within an array of objects passed from ember is "complex" this is an economical
  // solution that allows us to write simpler code on ember and hides the complexity in this bridge.
  mainButtonStateProps?: MainButtonStateProps;
}

export function PricePlansContainerBridge({
  pricePlans,
  customPricePlan,
}: PricePlansContainerProps): React.JSX.Element {
  const mappedPricePlans = pricePlans.map(plan => {
    const mainButton = (
      <Button stretch {...plan.mainButton.buttonProps} data-test-choose-plan-button>
        {plan.mainButton.text}
      </Button>
    );

    if (plan.recurrence === 'annual') {
      return { ...plan, mainButton };
    }

    return {
      ...plan,
      descriptionCta: (
        <Button
          className="caption"
          data-test-description-cta
          onPress={plan.descriptionCta.onPress}
          variant="tertiary"
        >
          {plan.descriptionCta.text}
        </Button>
      ),
      mainButton,
    };
  });

  if (customPricePlan) {
    return (
      <PricePlansContainer
        customPricePlan={{
          ...customPricePlan,
          mainButton: (
            <Button
              className="caption"
              stretch
              variant="secondary"
              {...customPricePlan.mainButton.buttonProps}
            >
              {customPricePlan.mainButton.text}
            </Button>
          ),
        }}
        pricePlans={mappedPricePlans}
      />
    );
  }

  return <PricePlansContainer pricePlans={mappedPricePlans} />;
}
