import { Factory } from 'miragejs';

import { assert } from '@repo/qonto-mirage/utils/assert';

export default Factory.extend({
  amount: 123,
  amountCurrency: 'EUR',
  canceledAt: null,
  shippingAddress: 'Qonto-SSP, 25 Rue Louis Le Petit, CS 60567, 75110 Paris Cedex 2',
  cmc7: '7945683062016706908053938954107',
  createdAt: () => new Date('2019-01-22T14:09:27.982Z'),
  creditDelayDays: 10,
  declinedAt: null,
  declinedReason: null,
  deletedAt: null,
  emittedDate: () => new Date('2019-02-10'),
  emitterName: 'John Doe',
  enrichmentData: () => {},
  receivedAt: null,
  refundedAt: null,
  rlmc: '97',
  sequentialId: i => i + 1,
  status: 'pending',
  updatedAt: () => new Date('2019-01-22T14:09:27.982Z'),
  validatedAt: null,

  afterCreate(check) {
    assert(check.bankAccount, `Missing 'bankAccount' relationship on 'check:${check.id}'`);

    if (!check.slug) {
      let orgSlug = check.bankAccount.organization.slug;
      let slug = `${orgSlug}-check-${check.sequentialId}`;
      check.update({ slug });
    }

    assert(
      !check.organization && !check.organizationId,
      `You should not pass an 'organization' relationship on 'check:${check.id}'`
    );
  },
});
