import { Factory } from 'miragejs';

import { assert } from '@repo/qonto-mirage/utils/assert';

export default Factory.extend({
  flowType: 'seamless',
  flowUserType: 'owner',
  legalFlow: 'company_creation',
  locale: 'fr',
  organizationLegalCountry: 'FR',
  organizationName: 'The Doors inc',
  ownerEmail: 'jim.morrison@qonto.com',
  ownerFirstName: 'Jim',
  partnerName: 'Legalstart',
  partnerType: 'legal_tech',

  afterCreate(model) {
    assert(
      model.partnerRegistration,
      `Missing 'partner-registration' relationship on 'redirection-token:${model.id}'`
    );
  },
});
