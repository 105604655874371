import * as React from 'react';
import { clsx } from 'clsx';
import { Button, LottiePlayer } from '@repo/design-system-kit';
import lottie from '../../assets/lotties/success.json';
import styles from './subscription-success.strict-module.css';

interface SubscriptionSuccessProps {
  title: string;
  subtitle: string;
  ctaText: string;
  onClick: () => void;
}

export function SubscriptionSuccess({
  title,
  subtitle,
  ctaText,
  onClick,
}: SubscriptionSuccessProps): React.JSX.Element {
  return (
    <div className={styles.container} data-test-subscription-success>
      <LottiePlayer
        animationData={lottie}
        className={styles.lottie}
        data-test-subscription-success-lottie
        loop={false}
      />
      <h1 className={clsx(styles.title, 'title-2')} data-test-subscription-success-title>
        {title}
      </h1>
      <p className={clsx(styles.subtitle, 'body-1')} data-test-subscription-success-subtitle>
        {subtitle}
      </p>
      <Button data-test-subscription-success-button onPress={onClick} stretch>
        {ctaText}
      </Button>
    </div>
  );
}
