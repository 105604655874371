import { Factory } from 'miragejs';

import { assert } from '@repo/qonto-mirage/utils/assert';

export default Factory.extend({
  activityDescription: null,
  addressCity: 'Paris',
  addressCountry: 'FR',
  addressFirstLine: 'Rue La Fayette',
  addressSecondLine: '20B',
  addressThirdLine: 'Suite 10',
  addressZipCode: '75009',
  contactEmail: 'johndoe@example.org',
  estimatedYearlyIncome: '€1000000',
  euVatNumber: 'FR11880118765',
  legalCode: '5499',
  legalName: 'Olinda',
  legalNumber: null,
  legalRegistrationDate: null,
  legalSector: '0125Z',
  name: 'Qonto',
  taxCountry: 'FR',
  taxIdentificationNumber: '88011876500010',
  websiteUrl: 'https://qonto.com',
  annual_turnover: '1000000-30000000',
  yearly_transaction_volume: '50000-100000',
  max_single_payment_amount: 10000,
  nature_of_operations: ['invoicing', 'spend_management'],

  afterCreate(organizationUpdate) {
    let modelName = 'kyc-kyb-update-organization-change-request';
    assert(
      organizationUpdate.kycKybUpdateProcess,
      `Missing 'kycKybUpdateProcess' relationship on '${modelName}:${organizationUpdate.id}'`
    );
  },
});
