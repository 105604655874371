import { Factory } from 'miragejs';

import { assert } from '@repo/qonto-mirage/utils/assert';

export default Factory.extend({
  sequentialId: i => i + 1,
  amount: 0,
  amountCurrency: 'EUR',
  enrichmentData: () => {},
  operationSubtype: 'subscription',
  operationType: 'fee',
  reference: i => `Reference ${i + 1}`,
  vatAmount: 0,
  vatRate: 0,

  afterCreate(model) {
    assert(model.bankAccount, `Missing 'bankAccount' relationship on 'walletToWallet:${model.id}'`);

    assert(
      !model.organization || !model.organizationId,
      `You should not pass an 'organization' relationship on 'walletToWallet:${model.id}'`
    );
  },
});
