import * as React from 'react';
import { SkeletonLoader } from '@repo/design-system-kit';
import { clsx } from 'clsx';
import { Carousel, CarouselItem } from '../carousel';
import loaderStyles from './price-plans-container.skeleton.strict-module.css';
import styles from './price-plans-container.strict-module.css';

function CardSkeleton(): React.JSX.Element {
  const features = [1, 2, 3, 4, 5, 6];

  return (
    <div className={loaderStyles.cardContainer} data-test-price-plan-card-loader>
      <SkeletonLoader.Line width="120px" />
      <div className={loaderStyles.block}>
        <SkeletonLoader.Line height="8px" width="100%" />
        <SkeletonLoader.Line height="8px" width="147px" />
      </div>
      <div className={loaderStyles.priceBox}>
        <div className={clsx(loaderStyles.block, loaderStyles.price)}>
          <SkeletonLoader.Line standalone width="64px" />
          <SkeletonLoader.Line standalone width="64px" />
          <SkeletonLoader.Line standalone width="136px" />
        </div>
        <SkeletonLoader.Line height="8px" width="80%" />
      </div>
      <div className={clsx(loaderStyles.block, loaderStyles.ctaBox)}>
        <SkeletonLoader.Block height="40px" width="100%" />
        <SkeletonLoader.Line height="8px" width="88px" />
      </div>
      {features.map(feature => (
        <div className={loaderStyles.block} key={feature}>
          <SkeletonLoader.Line height="8px" standalone width="100%" />
          <SkeletonLoader.Line height="8px" width="183px" />
        </div>
      ))}
    </div>
  );
}

export function PricePlanContainerSkeleton(): React.JSX.Element {
  return (
    <>
      <div className={styles.mobileView}>
        <div data-test-mobile-price-plan-loader>
          <Carousel options={{ align: 'start' }} slideSize="85%">
            {[1, 2, 3].map(card => (
              <CarouselItem className={styles.pricePlanWrapper} key={card}>
                <CardSkeleton />
              </CarouselItem>
            ))}
          </Carousel>
        </div>
      </div>
      <div className={styles.desktopView}>
        <div className={styles.container} data-test-desktop-price-plan-loader>
          <div className={clsx(styles.pricePlanWrapper, styles.column)}>
            <CardSkeleton />
          </div>
          <div className={clsx(styles.pricePlanWrapper, styles.column)}>
            <CardSkeleton />
          </div>
          <div className={clsx(styles.pricePlanWrapper, styles.column)}>
            <CardSkeleton />
          </div>
        </div>
      </div>
    </>
  );
}
