import React from 'react';
import styles from './text-with-highlights.strict-module.css';

interface TextWithHighlightsProps {
  children: string;
}

export function TextWithHighlights({ children }: TextWithHighlightsProps): React.ReactNode {
  const parseText = (text: string): JSX.Element[] => {
    const parts = text.split(/(?<highlight><highlight>.*?<\/highlight>)/g);
    return parts.map(part => {
      if (part.startsWith('<highlight>') && part.endsWith('</highlight>')) {
        return (
          <span className={styles.highlightedText} key={part}>
            {part.replace(/<\/?highlight>/g, '')}
          </span>
        );
      }
      return <span key={part}>{part}</span>;
    });
  };

  return <span>{parseText(children)}</span>;
}
