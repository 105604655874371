import * as React from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import { clsx } from 'clsx';
import styles from './carousel.strict-module.css';
import { Dots } from './dots';

export interface CarouselOptions {
  align?: 'start' | 'center' | 'end';
}

interface CarouselProps {
  children: React.ReactNode;
  slideSize?: number | string;
  slideSpacing?: number | string;
  options?: CarouselOptions;
}

export function Carousel({
  children,
  slideSize = '100%',
  slideSpacing = '1rem',
  options,
}: CarouselProps): React.JSX.Element {
  const [emblaRef, emblaApi] = useEmblaCarousel(options);
  const [index, setIndex] = React.useState(0);

  const slides = React.Children.toArray(children).length;

  const onScroll = React.useCallback(() => {
    const progress = emblaApi?.scrollProgress() ?? 0;

    setIndex(Math.round(progress * (slides - 1)));
  }, [emblaApi, slides]);

  React.useEffect(() => {
    emblaApi?.on('scroll', onScroll);
    emblaApi?.on('init', onScroll);
  }, [emblaApi, onScroll]);

  return (
    <>
      <Dots count={slides} index={index} />

      <div
        className={styles.emblaViewport}
        ref={emblaRef}
        style={
          {
            '--slide-size': slideSize,
            '--slide-spacing': slideSpacing,
          } as React.CSSProperties
        }
      >
        <div className={styles.emblaContainer}>{children}</div>
      </div>
    </>
  );
}

export function CarouselItem({
  className,
  children,
}: {
  children: React.ReactNode;
  className?: string;
}): React.JSX.Element {
  return <div className={clsx(styles.emblaSlide, className)}>{children}</div>;
}
