import { Factory } from 'miragejs';

import { assert } from '@repo/qonto-mirage/utils/assert';

export default Factory.extend({
  provider: 'idnow_videoident',
  providerId: i => `transaction_uuid-${i}`,
  providerStatus: 'pending',
  providerToken: i => `token-${i}`,
  reason: null,
  device: 'desktop',
  qontoRedirectionUrl: 'https://master.staging.qonto.co/',

  afterCreate(model) {
    if (!model.membershipId) {
      assert(model.membershipId, `A membership relationship is required for kyc ${model.id}`);
    }

    if (model.provider === 'fourthline') {
      model.update({ redirectUrl: 'https://kyc.qonto.com/v1/?code=XXXXXXXX' });
    }
  },
});
