import { Factory } from 'miragejs';

import { assert } from '@repo/qonto-mirage/utils/assert';

/**
 * https://openapi-master.staging.qonto-cbs.co/partner-register#operation/registrations/get
 */
export default Factory.extend({
  status: 'pending',
  partnerName: i => `Partner name ${i + 1}`,
  flowType: 'seamless',
  recommendedPricePlanCode: null,
  discountMonths: null,
  discountPricePlanCodes: () => [],
  legalFlow: 'company_creation',
  partnerType: 'legal_tech',
  companyCreationFromScratch: false,

  afterCreate(model, server) {
    assert(
      model.stakeholders.length >= 0,
      `Missing 'partner-stakeholder' relationship on model 'registration:${model.id}'`
    );

    assert(
      model.organization,
      `Missing 'partner-organization' relationship on model 'registration:${model.id}'`
    );

    if (model.status === 'completed') {
      let { id, legalName } = model.organization.attrs;

      server.create('bankAccount', {
        organization: server.create('organization', {
          id,
          name: legalName,
          underRegistration: true,
        }),
      });
    }
  },
});
