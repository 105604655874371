import { Factory } from 'miragejs';

import { assert } from '@repo/qonto-mirage/utils/assert';

export const EXERCISE_KIND_MONTHLY = 'monthly';
export const EXERCISE_KIND_YEARLY = 'yearly';
export const EXERCISE_KIND_GLOBAL = 'global';
export const EXERCISE_KINDS = Object.freeze([
  EXERCISE_KIND_MONTHLY,
  EXERCISE_KIND_YEARLY,
  EXERCISE_KIND_GLOBAL,
]);

export default Factory.extend({
  restricted: false,
  archived: false,
  exerciseKind: EXERCISE_KIND_MONTHLY,
  name: 'My Budget',
  supervisorIds: [],

  afterCreate(budget, server) {
    assert(budget.exerciseKind, `Missing 'exerciseKind' on 'budget:${budget.id}'`);
    assert(budget.assignedTo, `Missing 'assignedTo' on 'budget:${budget.id}'`);
    assert(
      EXERCISE_KINDS.includes(budget.exerciseKind),
      `'exerciseKind' of '${budget.exerciseKind}' is not allowed on 'budget:${budget.id}'`
    );

    if (budget.exercises.length === 0) {
      budget.update({
        exercises: [
          server.create('exercise', {
            budget,
            endDate: '2020-12-31',
            startDate: '2020-11-01',
          }),
        ],
      });
    }
  },
});
