import { Factory } from 'miragejs';

import { SHAREHOLDERS_DECLARATION_STATUS } from '@repo/qonto-mirage/constants/cc-shareholders-declaration';
import { assert } from '@repo/qonto-mirage/utils/assert';

export default Factory.extend({
  status: SHAREHOLDERS_DECLARATION_STATUS.SUBMITTED,

  afterCreate(model) {
    assert(
      model.creation,
      `Missing 'creation' relationship on 'cc-shareholders-declaration:${model.id}'`
    );

    assert(
      [SHAREHOLDERS_DECLARATION_STATUS.PENDING, SHAREHOLDERS_DECLARATION_STATUS.SUBMITTED].includes(
        model.status
      ),
      `'status' on 'cc-shareholders-declaration:${model.id}' must be of '${SHAREHOLDERS_DECLARATION_STATUS.PENDING}' or '${SHAREHOLDERS_DECLARATION_STATUS.SUBMITTED}'`
    );
  },
});
