import { Factory } from 'miragejs';

export default Factory.extend({
  recurrence: 'monthly',
  status: 'active',
  nextSubscription: null,
  activeTrial: null,
  nextRecurrenceDate: '2021-06-01T01:00:00.000Z',
  nextSubscriptionBillingDate: '2024-08-05T01:00:00.000Z',
  nextInvoicingDate: '2021-06-01T01:00:00.000Z',
  createdAt: () => new Date('2017-07-03T16:00:00Z'),
});
