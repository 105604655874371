import * as React from 'react';
import { clsx } from 'clsx';
import { SkeletonLoader } from '@repo/design-system-kit';
import styles from './checkout-header.strict-module.css';

export function CheckoutHeaderLoader(): React.JSX.Element {
  return (
    <div className={styles.container} data-test-checkout-header-loader>
      <div className={styles['loader-container']}>
        <SkeletonLoader.Line width="144px" />
      </div>
      <div className={styles['loader-container']}>
        <SkeletonLoader.Line width="48px" />
      </div>

      <div className={styles['loader-container']}>
        <SkeletonLoader.Line width="192px" />
      </div>
      <div className={styles['loader-container']}>
        <SkeletonLoader.Line width="48px" />
      </div>
    </div>
  );
}

interface CheckoutHeaderProps {
  title: React.ReactNode;
  subtitle: React.ReactNode;
  price: React.ReactNode;
  priceNote: React.ReactNode;
  elevation?: 'high';
}

export function CheckoutHeader({
  title,
  subtitle,
  price,
  priceNote,
  elevation,
}: CheckoutHeaderProps): React.JSX.Element {
  return (
    <div className={clsx(styles.container, elevation && styles['elevation-high'])}>
      <div className="title-4" data-test-checkout-header-title>
        {title}
      </div>
      <div className="title-4" data-test-checkout-header-price>
        {price}
      </div>

      <div className="body-2" data-test-checkout-header-subtitle>
        {subtitle}
      </div>
      <div className={clsx(styles['price-note'], 'caption')} data-test-checkout-header-price-note>
        {priceNote}
      </div>
    </div>
  );
}
