import { Factory } from 'miragejs';

import { CC_POI_DOCUMENTS_TYPES } from '@repo/qonto-mirage/constants/cc-poi-documents';
import { assert } from '@repo/qonto-mirage/utils/assert';

export default Factory.extend({
  type: CC_POI_DOCUMENTS_TYPES.EU_ID, // 'eu_id' | 'eu_passport' | 'resident_card'

  afterCreate(model) {
    assert(
      model.physicalEntity,
      `Missing 'physicalEntity' relationship on 'cc-poi-document:${model.id}'`
    );

    assert(model.files.length > 0, `Missing 'files' relationship on 'cc-poi-document:${model.id}'`);
  },
});
