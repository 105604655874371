import { JSONAPISerializer } from 'miragejs';

import { underscore } from '@repo/qonto-mirage/utils/string';

export default class TableTransactionSerializer extends JSONAPISerializer {
  alwaysIncludeLinkageData = true;

  include = ['labels', 'paymentMethod', 'source', 'target', 'attachments'];

  keyForAttribute(attr) {
    return underscore(attr);
  }

  serializerFor(type) {
    switch (type) {
      case 'label':
      case 'income':
      case 'transfer':
      case 'card':
        return super.serializerFor(`table-transactions/${type}`);
      case 'bank-account':
      case 'beneficiary':
        return super.serializerFor('table-transactions/bank-account');
      default:
        return super.serializerFor(type);
    }
  }

  keyForRelationship(attr) {
    if (attr === 'receivableInvoices') {
      return 'client_invoices';
    }

    return underscore(attr);
  }

  typeKeyForModel({ modelName }) {
    if (modelName === 'table-transaction') {
      return 'transaction';
    }

    if (modelName === 'receivable-invoice') {
      return 'client_invoice';
    }

    return underscore(modelName);
  }
}
