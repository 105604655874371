import dayjs from 'dayjs';
import { Factory } from 'miragejs';

import {
  PAY_IN,
  PAY_OUT,
  QUOTE_EXPIRATION_TIME_IN_SECONDS,
  QUOTE_STATUSES,
  QUOTE_TYPE,
  RATE_EXPIRATION_TIME_IN_SECONDS,
  RATE_TYPE,
} from '@repo/qonto-mirage/constants/international-out';

export default Factory.extend({
  createdTime: () => new Date(new Date().toUTCString()).toISOString(),
  expirationTime: () => dayjs().add(QUOTE_EXPIRATION_TIME_IN_SECONDS, 'second').toISOString(),
  payOut: PAY_OUT.OUR,
  paymentOptions: [
    {
      estimatedDelivery: () => dayjs().add(1, 'day').toISOString(),
      formattedEstimatedDelivery: 'tomorrow',
      payIn: PAY_IN.LOCAL,
      payOut: PAY_OUT.OUR,
      sourceAmount: 1000,
      sourceCurrency: 'EUR',
      targetAmount: 1080,
      targetCurrency: 'USD',
    },
  ],
  providedAmountType: QUOTE_TYPE.SOURCE,
  rate: 1.08,
  rateExpirationTime: () => dayjs().add(RATE_EXPIRATION_TIME_IN_SECONDS, 'second').toISOString(),
  rateType: RATE_TYPE.FIXED,
  status: QUOTE_STATUSES.PENDING,
  sourceAmount: 1000,
  sourceCurrency: 'EUR',
  targetAmount: 1080,
  targetCurrency: 'USD',
});
