import { JSONAPISerializer } from 'miragejs';

import { underscore } from '@repo/qonto-mirage/utils/string';

export default class TableIncomeSerializer extends JSONAPISerializer {
  alwaysIncludeLinkageData = false;
  attrs = ['fx', 'instant', 'internal', 'slug'];

  keyForAttribute(attr) {
    return underscore(attr);
  }

  keyForRelationship(attr) {
    return underscore(attr);
  }

  typeKeyForModel({ modelName }) {
    return underscore(modelName);
  }
}
