import { Factory } from 'miragejs';

import { assert } from '@repo/qonto-mirage/utils/assert';

export default Factory.extend({
  requestType: 'flash_card',
  note: '',
  declinedNote: '',
  status: 'pending',
  paymentLifespanLimit: null,
  currency: 'EUR',
  preExpiresAt: null,
  processedAt: null,
  createdAt: null,
  lastStep: true,
  warnings: () => [],

  afterCreate(request) {
    assert(
      request.organizationId,
      `Missing 'organization' relationship on 'request-flash-card: ${request.id}'`
    );

    if (new Date(request.preExpiresAt) <= new Date()) {
      let warnings = request.warnings;
      warnings.push('pre_expires_at_in_the_past');
      request.update({ warnings });
    }
  },
});
