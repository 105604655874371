import { Factory } from 'miragejs';

import { assert } from '@repo/qonto-mirage/utils/assert';

const generateLegalNumber = ({ countryCode, id }) => {
  switch (countryCode) {
    case 'IT':
      return `MI${id.toString().padStart(7, '0')}`;
    case 'DE':
      return `HRB${id.toString().padStart(5, '0')}`;
    case 'FR':
    default:
      return `${id.toString().padStart(9, '0')}${id.toString().padStart(5, '0')}`;
  }
};

export default Factory.extend({
  addressLine: '20 B Rue la Fayette',
  city: 'Paris',
  country: 'FR',
  name: i => `Company ${i + 1}`,
  zipcode: '75009',

  afterCreate(model) {
    assert(model.country, `Missing 'country' property on 'office:${model.id}'`);

    assert(
      model.country === model.country.toUpperCase(),
      `The 'country' property should always be UPPERCASE on 'office:${model.id}'`
    );

    // If the legalNumber is not specified we will create one based on the standard format of the country
    if (!model.legalNumber) {
      model.update({
        legalNumber: generateLegalNumber({ countryCode: model.country, id: model.id }),
      });
    }

    if (!model.companyInfoProviderId) {
      model.update({
        companyInfoProviderId: `${model.country}-0-${model.legalNumber}`,
      });
    }
  },
});
