import { Factory } from 'miragejs';

import { assert } from '@repo/qonto-mirage/utils/assert';

const VALID_ROLES = ['owner', 'admin', 'employee', 'reporting', 'manager'];
const VALID_CUSTOM_PERMISSION_SCOPES = ['team', 'organization'];

export default Factory.extend({
  name: 'owner',
  afterCreate(model) {
    assert(model.membershipId, `Missing 'membership' relationship on 'role:${model.id}'`);

    assert(VALID_ROLES.includes(model.name), `Name must be one of: ${VALID_ROLES.join(', ')}`);

    if (model.name === 'manager') {
      assert(
        VALID_CUSTOM_PERMISSION_SCOPES.includes(model?.customPermissions?.scope),
        `scope must be one of: ${VALID_CUSTOM_PERMISSION_SCOPES.join(', ')}`
      );
    }
  },
});
