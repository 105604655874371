import { Serializer } from 'miragejs';

import { underscore } from '@repo/qonto-mirage/utils/string';

export default class ReceivableInvoicesSharedCustomerSerializer extends Serializer {
  attrs = [
    'address',
    'city',
    'countryCode',
    'email',
    'kind',
    'locale',
    'name',
    'tinNumber',
    'zipCode',
  ];

  serializeIds = 'never';

  keyForAttribute(attr) {
    return underscore(attr);
  }
}
