import type { ReactElement, ReactNode } from 'react';
import type { ButtonProps as AriaButtonProps } from 'react-aria-components';
import { Button as AriaButton } from 'react-aria-components';
import cx from 'clsx';
import styles from '../../primitives/button.strict-module.css';
import { Spinner } from '../spinner';

export interface BaseButtonProps {
  variant?: 'primary' | 'secondary' | 'tertiary' | 'danger';
  size?: 'large' | 'medium' | 'small';
  iconOnly?: boolean;
  stretch?: boolean;
  isLoading?: boolean;
}

interface ButtonProps extends AriaButtonProps, BaseButtonProps {
  children: ReactNode;
}

export function Button({
  children,
  variant = 'primary',
  size = 'medium',
  iconOnly = false,
  stretch = false,
  isLoading = false,
  isDisabled,
  className,
  ...props
}: ButtonProps & { children: React.ReactNode }): ReactElement {
  return (
    <AriaButton
      className={cx(
        'body-1',
        styles.btn,
        variant !== 'primary' && styles[`btn--${variant}`],
        size !== 'medium' && styles[`btn--${size}`],
        iconOnly && styles['btn--icon-only'],
        stretch && styles[`btn--stretch`],
        className
      )}
      isDisabled={isLoading || isDisabled}
      {...props}
    >
      <div className={cx(styles['button-wrapper'])}>
        {children}
        {isLoading ? (
          <Spinner
            className="ml-8"
            size="small"
            {...((variant === 'secondary' || variant === 'tertiary') && { color: 'primary-a' })}
            {...(variant === 'danger' && { color: 'primary-b-static' })}
          />
        ) : null}
      </div>
    </AriaButton>
  );
}
