/* eslint-disable camelcase */
import { JSONAPISerializer } from 'miragejs';

import { underscore } from '@repo/qonto-mirage/utils/string';

export default class TableIncomeSerializer extends JSONAPISerializer {
  alwaysIncludeLinkageData = false;

  keyForAttribute(attr) {
    return underscore(attr);
  }

  keyForRelationship(attr) {
    return underscore(attr);
  }

  typeKeyForModel({ modelName }) {
    return underscore(modelName);
  }

  _attrsForModel(model) {
    let { emitter_bic, emitter_iban, emitter_name, slug } = super._attrsForModel(model);

    return {
      bic: emitter_bic,
      iban: emitter_iban,
      name: emitter_name,
      slug,
    };
  }
}
