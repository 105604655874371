import { Factory } from 'miragejs';

import { assert } from '@repo/qonto-mirage/utils/assert';

export default Factory.extend({
  status: 'rewarded',
  email: 'john.appleseed@apple.com',
  rewardAmount: 100,
  rewardAmountCents: 10000,
  rewardAmountCurrency: 'EUR',

  registeredAt: '2019-06-17T07:38:54.000Z',
  rewardedAt: '2019-06-18T07:38:54.000Z',
  createdAt: '2018-06-17T07:38:54.000Z',

  afterCreate(referral) {
    assert(referral.referrer, `Missing 'referrer' relationship on 'referral:${referral.id}'`);
  },
});
