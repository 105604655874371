import { association, Factory } from 'miragejs';

import { assert } from '@repo/qonto-mirage/utils/assert';

export default Factory.extend({
  email: 'some@email.com',
  firstName: 'Pippo',
  lastName: 'Pippuccio',
  provider: 'personio', // nullable for old suggested
  department: 'Sales', // nullable for old suggested
  jobTitle: 'Lead', // nullable for old suggested
  avatarUrl: 'http://some.link.to.avatar.com',
  organization: association(),

  afterCreate(suggestedInvitation) {
    assert(
      suggestedInvitation.provider,
      `Missing 'provider' on 'suggestedInvitation:${suggestedInvitation.id}'`
    );
  },
});
