import { pluralize } from 'ember-inflector';
import { JSONAPISerializer } from 'miragejs';

import { underscore } from '@repo/qonto-mirage/utils/string';

export default class AccountsReceivableOnboardingSerializer extends JSONAPISerializer {
  alwaysIncludeLinkageData = true;

  keyForAttribute(attr) {
    return underscore(attr);
  }

  keyForRelationship(key) {
    return underscore(key);
  }

  typeKeyForModel({ modelName }) {
    if (modelName === 'accounts-receivable-onboarding') {
      return underscore(modelName);
    }
    return underscore(pluralize(modelName));
  }
}
