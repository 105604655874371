import { Factory } from 'miragejs';

import { assert } from '@repo/qonto-mirage/utils/assert';

export default Factory.extend({
  value: null,

  afterCreate(model) {
    assert(
      model.stakeholder,
      `Missing 'partner-stakeholder' relationship on model 'partner-kyc-sdk-token:${model.id}'`
    );
  },
});
