import { Factory } from 'miragejs';

export default Factory.extend({
  partnerName: i => `Partner name ${i + 1}`,
  flowType: 'seamless',
  partnerType: 'legal_tech',

  afterCreate(model, server) {
    server.create('register-organization', {
      partnerRegistration: model,
      user: model.user,
      underRegistration: false,
      ...(model?.registerOrganization || {}),
    });
  },
});
