import type { ReactElement } from 'react';
import cx from 'clsx';
import type { PressEvent } from 'react-aria-components';
import { Button } from '../button';
import { CloseIcon } from '../../assets/icons/close-icon';
import styles from './toast.strict-module.css';
import { InfoIcon, ErrorIcon, SuccessIcon } from './icons';

interface ToastProps {
  type?: 'info' | 'success' | 'error';
  text: string;
  onClose: (e: PressEvent) => void;
  closeButtonAriaLabel?: string;
  className?: string;
}

export function Toast({
  type = 'info',
  text,
  onClose,
  closeButtonAriaLabel = 'Close',
  className,
}: ToastProps): ReactElement {
  return (
    <div className={cx(styles.toast, type === 'error' && styles.error, className)} data-test-toast data-test-type={type}>
      <div className={styles['icon-container']}>
        {type === 'info' && <InfoIcon className={styles.icon} />}
        {type === 'success' && <SuccessIcon className={styles.icon} />}
        {type === 'error' && <ErrorIcon className={styles.icon} />}
      </div>
      <p className={cx('body-1', styles.text)}>{text}</p>
      <Button
        aria-label={closeButtonAriaLabel}
        className={cx(styles.close)}
        iconOnly
        onPress={onClose}
        variant="tertiary"
      >
        <CloseIcon />
      </Button>
    </div>
  );
}
