import type { ReactElement } from 'react';
import { useState, useEffect } from 'react';
import type {
  ValidationResult,
  NumberFieldProps as AriaNumberFieldProps,
} from 'react-aria-components';
import { NumberField as AriaNumberField } from 'react-aria-components';
import cx from 'clsx';
import { I18nProvider } from '@react-aria/i18n';
import type { LocaleCode } from '@repo/shared-config/constants/locales';
import { DEFAULT_LOCALE } from '@repo/shared-config/constants/locales';
import { Description, Input, Error, Label } from '../form-elements';
import styles from './amount-field.strict-module.css';

interface AmountFieldComponentProps extends AriaNumberFieldProps {
  amount: string | number | undefined | null;
  label: string;
  description?: string;
  errorMessage?: string | ((validation: ValidationResult) => string);
  placeholder?: string;
  currency?: string;
  decimals?: number;
  locale?: LocaleCode;
  onAmountChange?: (value: string) => void;
}

const getFormattedValue = (
  amount: string | number | undefined | null,
  decimals: number
): number => {
  // . falsy values or zero (type number) set as default are used to show a placeholder
  if (
    amount === null ||
    amount === undefined ||
    amount === '' ||
    amount === 0 ||
    Number.isNaN(amount)
  ) {
    return NaN;
  }

  if (typeof amount === 'number') {
    return parseFloat(amount.toFixed(decimals));
  }

  if (typeof amount === 'string') {
    const parsedAmount = parseFloat(amount);

    //. zero returned from parsed string is ok as it's a user-set value
    if (!isNaN(parsedAmount)) {
      return parseFloat(parsedAmount.toFixed(decimals));
    }
  }

  return NaN;
};

export function AmountField({
  amount,
  label,
  description,
  errorMessage,
  placeholder = '0.00',
  currency = 'EUR',
  locale,
  decimals = 2,
  className,
  onAmountChange,
  ...props
}: AmountFieldComponentProps): ReactElement {
  const [currentValue, setCurrentValue] = useState<number | undefined>();

  useEffect(() => {
    const newValue = getFormattedValue(amount, decimals);

    setCurrentValue(newValue);
  }, [amount, decimals]);

  const handleChange = (newValue: number): void => {
    // . valid number
    if (!isNaN(newValue)) {
      setCurrentValue(newValue);

      if (onAmountChange) {
        onAmountChange(newValue.toFixed(decimals));
      }

      return;
    }

    // . invalid number
    if (onAmountChange) {
      setCurrentValue(NaN);
      onAmountChange('');
    }
  };

  return (
    <I18nProvider locale={locale || DEFAULT_LOCALE}>
      <AriaNumberField
        className={cx(className, styles.container)}
        formatOptions={{
          minimumFractionDigits: decimals,
          maximumFractionDigits: decimals,
        }}
        onChange={handleChange}
        value={currentValue}
        {...props}
      >
        {Boolean(label) && <Label>{label}</Label>}
        <div className={styles['input-wrapper']}>
          <span className={cx(styles.currency, 'body-1', currentValue && styles['with-value'])}>
            {currency}
          </span>
          <Input className={styles.input} placeholder={placeholder} />
        </div>
        <Error>{errorMessage}</Error>
        {Boolean(description) && <Description>{description}</Description>}
      </AriaNumberField>
    </I18nProvider>
  );
}
