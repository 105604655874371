import { JSONAPISerializer } from 'miragejs';

import { underscore } from '@repo/qonto-mirage/utils/string';

export default class TableIncomeSerializer extends JSONAPISerializer {
  alwaysIncludeLinkageData = false;

  keyForAttribute(attr) {
    return underscore(attr);
  }

  keyForRelationship(attr) {
    return underscore(attr);
  }

  typeKeyForModel({ modelName }) {
    return underscore(modelName);
  }

  _attrsForModel(card) {
    let { nickname } = super._attrsForModel(card);

    return {
      nickname,
      last4: card.pan.slice(-4),
    };
  }
}
