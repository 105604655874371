import { classify } from '@repo/qonto-mirage/utils/string';

import ApplicationSerializer from './application';

const SUBJECT_INCLUDES = [
  'cards',
  'checks',
  'direct_debits',
  'incomes',
  'transfers',
  'wallet_to_wallets',
  'swift_incomes',
];

export default ApplicationSerializer.extend({
  serializeIds: 'always',

  include(request) {
    if (request.method === 'POST') {
      let includedResources = [];
      let includes = JSON.parse(request.requestBody).includes || [];
      let isSubjectIncluded = includes.some(it => SUBJECT_INCLUDES.includes(it));

      if (isSubjectIncluded) {
        includedResources.push('subject');
      }

      if (includes.includes('beneficiaries')) {
        includedResources.push('beneficiaries');
      }

      if (includes.includes('claims')) {
        includedResources.push('claims');
      }

      return includedResources;
    } else {
      return ['subject', 'initiator'];
    }
  },

  serialize(_, request) {
    let json = ApplicationSerializer.prototype.serialize.apply(this, arguments);

    if (json.transaction) {
      this._adjust(json.transaction);
      return { transaction: json.transaction };
    } else if (json.transactions) {
      for (let transaction of json.transactions) {
        this._adjust(transaction);
      }

      if (request.method === 'POST') {
        let includes = JSON.parse(request.requestBody).includes || [];

        if (includes.includes('beneficiaries')) {
          json.beneficiaries = this._relatedBeneficiaries(json.transactions);
        }

        if (includes.includes('claims')) {
          json.claims = this._relatedClaims(json.transactions);
        }
      }
    }

    return json;
  },

  _adjust(transaction) {
    let { schema } = this.registry;

    let transactionRecord = schema.transactions.find(transaction.id);
    let vatRecords = schema.vats.where({ transactionId: transaction.id });
    let { vats } = this.serialize(vatRecords);

    transaction.has_attachment = transactionRecord.attachmentIds.length !== 0;

    if (transaction.subject_type) {
      transaction.subject_type = classify(transaction.subject_type);
    } else {
      transaction.subject_id = null;
      transaction.subject_type = null;
    }

    let bankAccount = schema.bankAccounts.find(transaction.bank_account_id);
    if (bankAccount.organizationId) {
      transaction.organization_id = bankAccount.organizationId;
    }

    /**
     * V6 vat management - else V5 compatibility
     */
    if (vats?.length) {
      let totalAmount = this._computeVatTotalAmount(vats, transaction.amount_currency);

      transaction.vat = {
        total_amount: totalAmount,
        items: vats,
        ...transaction.vat,
      };

      delete transaction.vat_amount;
      delete transaction.vat_country;
      delete transaction.vat_status;
    } else {
      delete transaction.vat;
    }
  },

  _relatedBeneficiaries(transactions) {
    let { schema } = this.registry;

    let beneficiaryIds = [];

    transactions.forEach(it => {
      if (it.subject_id) {
        let subject = schema.collectionForType(it.subject_type).find(it.subject_id);
        let { beneficiaryId } = subject;

        if (beneficiaryId && !beneficiaryIds.includes(beneficiaryId)) {
          beneficiaryIds.push(beneficiaryId);
        }
      }
    });

    let beneficiariesRecords = schema.beneficiaries.find(beneficiaryIds);
    let { beneficiaries } = this.serialize(beneficiariesRecords);

    return beneficiaries;
  },

  _relatedClaims(transactions) {
    let unserializedClaims = this.registry.schema.claims.all();

    let { claims } = this.serialize(unserializedClaims);

    let relatedClaims = new Set();

    transactions.forEach(transaction => {
      claims.forEach(claim => {
        if (claim.transaction_ids.includes(transaction.id)) {
          relatedClaims.add(claim);
        }
      });
    });

    return [...relatedClaims];
  },

  _computeVatTotalAmount(vats, amountCurrency) {
    let totalAmountValue = vats.reduce((totalAmount, { amount: { value } }) => {
      let parsedValue = parseFloat(value);

      return isNaN(parsedValue) ? totalAmount : totalAmount + Math.round(parsedValue * 100) / 100;
    }, 0);

    return { value: totalAmountValue.toFixed(2), currency: amountCurrency || 'EUR' };
  },
});
