import { JSONAPISerializer } from 'miragejs';

import { underscore } from '@repo/qonto-mirage/utils/string';

export default class TableLabelSerializer extends JSONAPISerializer {
  alwaysIncludeLinkageData = false;

  keyForAttribute(attr) {
    return underscore(attr);
  }

  keyForRelationship(attr) {
    return underscore(attr);
  }

  typeKeyForModel({ modelName }) {
    return underscore(modelName);
  }

  _attrsForModel(model) {
    let { labelList } = model;
    let attrs = super._attrsForModel(model);

    delete attrs.created_at;

    return {
      ...attrs,
      category_id: labelList.id,
    };
  }
}
