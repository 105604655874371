import { Factory } from 'miragejs';

import { assert } from '@repo/qonto-mirage/utils/assert';

export default Factory.extend({
  legalName: 'Auto Brunner GmbH',
  legalNumber: 'HRB12345',

  afterCreate(model) {
    assert(model.creation, `Missing 'creation' relationship on 'cc-legal-entity:${model.id}'`);
  },
});
