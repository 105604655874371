import { pluralize } from 'ember-inflector';

import { underscore } from '@repo/qonto-mirage/utils/string';

import PartnerSerializer from './-partner';

export default class RegistrationsSerializer extends PartnerSerializer {
  shouldIncludeLinkageData(relationshipKey) {
    /**
     * Taken from this list :
     * https://gitlab.qonto.co/back/partner-register/-/blob/master/app/serializers/client_api/redirection_token_serializer.rb
     **/
    return relationshipKey === 'registration';
  }

  keyForAttribute(attr) {
    // use snake_case for attribute names
    return underscore(attr);
  }

  typeKeyForModel({ modelName }) {
    return underscore(pluralize(modelName));
  }
}
