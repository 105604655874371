import dayjs from 'dayjs';
import { association, Factory, trait } from 'miragejs';

import { FREQUENCY, STATUS } from '@repo/qonto-mirage/constants/invoice-subscription';
import { assert } from '@repo/qonto-mirage/utils/assert';

export default Factory.extend({
  status: STATUS.ACTIVE,
  startDate: () => dayjs().format('YYYY-MM-DD'), // Date eg. 2024-03-25
  endDate: () => dayjs().add(3, 'month').format('YYYY-MM-DD'), // Date 3 months from now eg. 2024-06-25
  nextInvoiceDate: () => dayjs().add(1, 'month').format('YYYY-MM-DD'), // Date eg. 2024-04-25
  prevInvoiceDate: () => dayjs().format('YYYY-MM-DD'), // Date eg. 2024-03-25
  createdAt: () => dayjs().toISOString(),
  currency: 'EUR',
  frequency: {
    value: 1,
    recurrence: FREQUENCY.MONTHLY,
  },
  paymentTermsDays: 15,
  emailTemplate: {
    email_title: 'Email Title',
    email_body: 'Email Body',
    send_to: ['test@qonto.com'],
    copy_to_self: true,
  },
  purchaseOrder: 'PO#1234',
  beneficiaryName: 'Mary Loise',
  termsAndConditions: 'Terms and Conditions',
  header: 'Header',
  footer: 'Footer',
  directDebitEnabled: false,
  amountDue: {
    value: '120.00',
    currency: 'EUR',
  },
  items: [
    {
      id: () => crypto.randomUUID(),
      title: 'Leather Jacket',
      description: 'Size M, Black, 100% Leather',
      unit_price: {
        value: '120.00',
        currency: 'EUR',
      },
      quantity: '1',
      vat_rate: '0.2',
      discount: {
        type: 'percentage',
        value: '0.1',
      },
      created_at: () => dayjs().toISOString(),
    },
  ],

  organization: association(),

  afterCreate(subscription, server) {
    assert(
      subscription.organization,
      `Missing 'organization' relationship on 'invoice-subscription:${subscription.id}'`
    );

    if (!subscription.customer) {
      subscription.update({
        customer: server.create('customer', {
          organization: subscription.organization,
          name: 'John Doe',
          tinNumber: '73282932000074',
        }),
      });
    }

    if (!subscription.customerName) {
      subscription.update({
        customerName: subscription.customer.name,
      });
    }

    if (!subscription.bankAccount) {
      subscription.update({
        bankAccount: server.create('bankAccount', { organization: subscription.organization }),
      });
    }

    // initialize IT fields
    if (subscription.organization.legalCountry === 'IT') {
      if (!subscription.stampDutyAmount) {
        subscription.update({
          stampDutyAmount: '10.00',
        });
      }

      if (!subscription.payment) {
        subscription.update({
          payment: {
            conditions: 'TP02',
            method: 'MP05',
          },
        });
      }

      if (!subscription.welfareFund) {
        subscription.update({
          welfareFund: {
            type: 'TC01',
            rate: '0.1',
          },
        });
      }

      if (!subscription.withholdingTax) {
        subscription.update({
          withholdingTax: {
            type: 'RF01',
            rate: '0.1',
          },
        });
      }
    }
  },

  withIssuedInvoice: trait({
    afterCreate(subscription, server) {
      server.create('receivable-invoice', {
        number: 'INV-1',
        status: 'unpaid',
        issueDate: subscription.prevInvoiceDate,
        dueDate: dayjs(subscription.prevInvoiceDate)
          .add(subscription.paymentTermsDays, 'day')
          .format('YYYY-MM-DD'),
        createdAt: subscription.prevInvoiceDate,
        currency: subscription.currency,
        emailTemplate: subscription.emailTemplate,
        purchaseOrder: subscription.purchaseOrder,
        beneficiaryName: subscription.beneficiaryName,
        termsAndConditions: subscription.termsAndConditions,
        header: subscription.header,
        footer: subscription.footer,
        amountDue: subscription.amountDue,
        items: subscription.items,
        locale: subscription.customer.locale,
        payment: subscription.payment,
        welfareFund: subscription.welfareFund,
        withholdingTax: subscription.withholdingTax,
        stampDutyAmount: subscription.stampDutyAmount,
        organization: subscription.organization,
        customer: subscription.customer,
        bankAccount: subscription.bankAccount,
        invoiceSubscription: subscription,
      });
    },
  }),
});
