import { Factory } from 'miragejs';

import { assert } from '@repo/qonto-mirage/utils/assert';
export default Factory.extend({
  afterCreate(model) {
    assert(
      model.organization,
      `Missing 'organization' relationship on 'RemuneratedAccount:${model.id}'`
    );
  },
});
