import { Factory } from 'miragejs';

import { camelize } from '@repo/qonto-mirage/utils/string';

const FAKE_NAMES = ['PayFit', 'Quickbooks', 'Google', 'Netflix', 'GitHub'];

export default Factory.extend({
  name: i => FAKE_NAMES[i % FAKE_NAMES.length],
  slug() {
    return camelize(this.name);
  },
  applicationType: 'notifier',
  createdAt: '2020-07-24T12:34:56Z',
  params: () => {},
});
