import { Factory } from 'miragejs';

import { assert } from '@repo/qonto-mirage/utils/assert';

export default Factory.extend({
  activatedAt: () => new Date('2017-02-28T13:55:28Z'),
  activeDays: () => [0, 1, 2, 3, 4, 5, 6],
  atmDailyLimit: 20,
  atmDailyLimitOption: false,
  atmDailySpent: 0,
  atmMonthlyLimit: 20,
  atmMonthlySpent: 0,
  atmOption: true,
  categoryTags: () => null,
  cardDesign: null,
  cardLevel: 'standard',
  createdAt: () => new Date('2017-02-24T12:34:56Z'),
  currency: 'EUR',
  cvv: '123',
  discardOn: null, // YYYY-MM-DD string
  embossedName: 'John Doe',
  eligibleForRenewal: false,
  eligibleForUpsell: false,
  expDate: '2022-04-30',
  expMonth: '4',
  expYear: '2022',
  foreignOption: true,
  forwardedAt: () => new Date('2019-01-28T17:54:57Z'),
  hadOperation: false,
  hadPinOperation: false,
  isQcp: false,
  lastActivityAt: () => new Date('2019-09-28T12:54:12Z'),
  nfcOption: true,
  nickname: 'card nickname',
  onlineOption: true,
  pan: i => String(5314423729180128 + i),
  parentCardSummary: null,
  paymentDailyLimit: 20,
  paymentDailyLimitOption: false,
  paymentDailySpent: 0,
  paymentLifespanLimit: 100,
  paymentLifespanSpent: 0,
  paymentMonthlyLimit: 20,
  paymentMonthlySpent: 0,
  paymentTransactionLimit: 20,
  paymentTransactionLimitOption: false,
  pin: '0816',
  renewal: false,
  renewed: false,
  sequentialId: i => i + 1,
  shippedAt: () => new Date('2017-02-28T13:55:28Z'),
  shipToBusiness: true,
  status: 'live',
  updatedAt: () => new Date('2019-01-28T17:54:57Z'),
  upsell: false,
  upsold: false,

  afterCreate(model) {
    assert(model.bankAccount, `Missing 'bankAccount' relationship on 'card:${model.id}'`);

    if (!model.slug) {
      let orgSlug = model.bankAccount.organization && model.bankAccount.organization.slug;
      let slug = (orgSlug || '') + (orgSlug ? '-' : '') + `card-${model.sequentialId}`;
      model.update({ slug });
    }

    if (model.cardLevel === 'metal' && model.status === 'live') {
      let { features } = model.holder;
      let updatedFeatures = new Set([...features, 'concierge', 'lounge']);
      model.holder.update({ features: [...updatedFeatures] });
    }

    if (model.status === 'pending') {
      model.activatedAt = null;
    }

    assert(
      !model.organization && !model.organizationId,
      `You should not pass an 'organization' relationship on 'card:${model.id}'`
    );
  },
});
