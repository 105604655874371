import { pluralize } from 'ember-inflector';

import { underscore } from '@repo/qonto-mirage/utils/string';

import ApplicationSerializer from './application';

export default class MembershipKybDetailSerializer extends ApplicationSerializer {
  keyForRelationship(modelName) {
    return underscore(pluralize(modelName));
  }
}
