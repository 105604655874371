import { Factory } from 'miragejs';

import { assert } from '@repo/qonto-mirage/utils/assert';

export default Factory.extend({
  name: i => `team${i + 1}`,
  afterCreate(model) {
    assert(model.organization, `Missing 'organization' relationship on 'team:${model.id}'`);
  },
});
