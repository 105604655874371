import { Factory } from 'miragejs';

import { assert } from '@repo/qonto-mirage/utils/assert';

export default Factory.extend({
  createdAt: '2021-03-22T15:26:57.654Z',
  status: 'submitted',
  updatedAt: '2021-03-22T15:26:57.654Z',

  afterCreate(model) {
    assert(
      model.creation,
      `Missing 'creation' relationship on 'cc-application-review:${model.id}'`
    );
  },
});
