import dayjs from 'dayjs';
import { Factory } from 'miragejs';

import { assert } from '@repo/qonto-mirage/utils/assert';

const ONE_DAY_IN_SECONDS = 86400;
const rfc3339Format = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]';

export default Factory.extend({
  startDate: '', // RFC3339 date: 2022-10-02T15:00:00Z
  endDate: '', // RFC3339 date
  deactivationDueDate: '', // RFC3339 date, null
  status: 'waiting', // waiting, reviewable, rejected, accepted,

  afterCreate(model) {
    assert(model.organization, `Missing 'organization' relationship on 'flex-kyb:${model.id}'`);

    assert(model.startDate, `Missing 'startDate' on 'flex-kyb:${model.id}'`);

    let startDate = dayjs(model.startDate);
    let startDateUnix = startDate.unix();

    let endDateUnix = startDateUnix + ONE_DAY_IN_SECONDS * 30;
    let endDate = dayjs.unix(endDateUnix);

    let deactivationDueDate = null;
    if (model.deactivationDueDate !== null) {
      let deactivationDueDateUnix = endDateUnix + ONE_DAY_IN_SECONDS * 30;
      deactivationDueDate = dayjs.unix(deactivationDueDateUnix).utc().format(rfc3339Format);
    }

    model.update({
      endDate: endDate.utc().format(rfc3339Format),
      deactivationDueDate,
    });
  },
});
