import { JSONAPISerializer } from 'miragejs';

import { dasherize, decamelize, underscore } from '@repo/qonto-mirage/utils/string';
import transformKeys from '@repo/qonto-mirage/utils/transform-keys';

export default class extends JSONAPISerializer {
  alwaysIncludeLinkageData = true;

  keyForAttribute(attr) {
    return underscore(attr);
  }

  keyForRelationship(key) {
    if (key === 'clientHub') {
      return 'customer';
    }

    return dasherize(key);
  }

  typeKeyForModel() {
    let type = super.typeKeyForModel(...arguments);
    switch (type) {
      case 'receivable-invoices-reminders':
        return 'reminders';
      case 'client-hubs':
        return 'customers';
      default:
        return type;
    }
  }

  serialize() {
    let { schema } = this.registry;
    let json = super.serialize(...arguments);

    json.data.attributes.rules = transformKeys(
      schema.reminderRules
        .where({
          receivableInvoicesReminderId: json.data.id,
        })
        .models.map(({ operator, field, offsetDays, emailTitle, emailBody }) => ({
          operator,
          field,
          offsetDays,
          emailTitle,
          emailBody,
        })),
      decamelize
    );

    delete json.data.relationships.organization;
    delete json.data.relationships['reminder-rules'];

    return json;
  }

  normalize() {
    let json = super.normalize(...arguments);

    if (json.data.relationships?.customer) {
      json.data.relationships.clientHub = json.data.relationships.customer;
      delete json.data.relationships.customer;
      json.data.relationships.clientHub.data.type = 'client-hubs';
    }

    return json;
  }
}
