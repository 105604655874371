import { association, Factory } from 'miragejs';

import { assert } from '@repo/qonto-mirage/utils/assert';

export default Factory.extend({
  birthCity: 'Paris',
  birthCountry: 'FR',
  birthZipcode: '12345',
  birthdate: '1991-10-10',
  capitalAmount: null,
  createdAt: '2021-03-22T15:26:57.654Z',
  email: i => `stakeholder${i + 1}@qonto.eu`,
  firstName: 'Steak',
  lastName: i => `Holder ${i + 1}`,
  nationality: 'FR',
  shareholder: false,

  address: association(),

  afterCreate(model) {
    assert(model.organization, `Missing 'organization' relationship on 'stakeholder:${model.id}'`);
  },
});
