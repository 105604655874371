import { Factory } from 'miragejs';

import { assert } from '@repo/qonto-mirage/utils/assert';

export default Factory.extend({
  code: 'essential',
  bankAccountLimit: -1,
  receivableInvoiceGlobalLimit: -1,
  checkLimit: 10,
  deliveriesLimit: 5,
  groupCode: 'essential',
  monthlyPrice: {
    value: '19',
    currency: 'EUR',
  },
  annualPrice: {
    value: '249',
    currency: 'EUR',
  },
  physicalCardLimit: 5,
  sepaOutLimit: 500,
  userLimit: 10,
  virtualCardLimit: -1,
  accountantLimit: 10,
  brandName: () => ({}),
  features: () => [],
  lineup: 'teams',
  options: () => [],
  disabled: false,

  afterCreate(pricePlan) {
    if (pricePlan.options.length) {
      pricePlan.options.forEach(option => {
        assert(typeof option.code === 'string', 'option code must be a string');
        assert(typeof option.frequency === 'string', 'option frequency must be a string');
        assert(
          option.price.value && typeof option.price.value === 'string',
          'option price must be an object with a value string property'
        );
        assert(
          option.price.currency && typeof option.price.currency === 'string',
          'option price must be an object with a currency string property'
        );
        assert(
          option.rate === null || typeof option.rate === 'number',
          'option rate type must be null or a number'
        );
        assert(typeof option.rate_type === 'string', 'option rate_type must be a string');
      });
    }
  },
});
