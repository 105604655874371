import { Factory } from 'miragejs';

import { assert } from '@repo/qonto-mirage/utils/assert';

const assertPresence = function (period, attr) {
  assert(period[attr], `Missing '${attr}' on 'period:${period.id}'`);
};

export default Factory.extend({
  afterCreate(period) {
    assertPresence(period, 'amountBudgeted');
    assertPresence(period, 'endDate');
    assertPresence(period, 'startDate');
  },
});
