import { Factory } from 'miragejs';

import { assert } from '@repo/qonto-mirage/utils/assert';

export default Factory.extend({
  requestType: 'multi_transfer',
  note: null,
  declinedNote: null,
  status: null,
  lastStep: true,
  operationType: null,
  scheduledDate: null,
  totalTransfersAmount: null,
  totalTransfersAmountCurrency: null,
  totalTransfersCount: null,
  processedAt: null,
  createdAt: null,

  afterCreate(request) {
    assert(
      request.initiator || request.initiatorId,
      `Missing 'initiator' (membership) relationship on 'request-multi-transfer:${request.id}'`
    );

    assert(
      request.bankAccount || request.bankAccountId,
      `Missing 'bankAccount' relationship on 'request-multi-transfer:${request.id}'`
    );
  },
});
