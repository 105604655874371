import { Factory } from 'miragejs';

import { assert } from '@repo/qonto-mirage/utils/assert';

export default Factory.extend({
  url: 'https://img.qonto.co/storage/2cAKBI0Q.png',
  status: 'generated',
  userInput: 'Create an image of a fluffy cat knitting a scarf',
  color: 'red',
  style: 'minimalist',
  createdAt: '2023-01-17T07:38:54.000Z',

  afterCreate(logo) {
    assert(logo.organization, `Missing 'organization' relationship on 'logo:${logo.id}'`);
  },
});
