import { Factory } from 'miragejs';

import { API } from '@repo/qonto-mirage/hosts';
import { assert } from '@repo/qonto-mirage/utils/assert';

const RE_IMAGE = /(?:jpg|jpeg|png)/;

export default Factory.extend({
  createdAt: '2018-06-17T07:38:54.000Z',
  downloadUrl: null,
  fileContentType: 'image/png',
  fileName: 'foobar.png',
  fileSize: 800,
  fileUrl: null,
  probativeAttachmentFileName: null,
  probativeAttachmentFileSize: 699,
  probativeAttachmentFileUrl: null,
  probativeAttachmentStatus: 'pending',
  sequentialId: i => i + 1,
  slug: null,
  thumbnailUrl: null,
  attachmentType: 'foobar_type',

  afterCreate(attachment) {
    assert(
      attachment.organization,
      `Missing 'organization' relationship on 'attachment:${attachment.id}'`
    );

    if (!attachment.slug) {
      let orgSlug = attachment.organization.slug;
      let slug = `${orgSlug}-attachment-${attachment.sequentialId}`;
      attachment.update({ slug });
    }

    if (!attachment.fileUrl) {
      let fileUrl = `${API}/v3/attachments/${attachment.id}/${attachment.fileName}`;
      attachment.update({ fileUrl });
    }

    if (!attachment.thumbnailUrl) {
      let fileName = attachment.fileName.replace(/[^.]*./, 'thumbnail.');
      let thumbnailUrl = `${API}/v3/attachments/${attachment.id}/${fileName}`;
      attachment.update({ thumbnailUrl });
    }

    if (!attachment.downloadUrl) {
      let fileName = attachment.fileName.replace(/[^.]*./, 'download.');
      let downloadUrl = `${API}/v3/attachments/${attachment.id}/${fileName}`;
      attachment.update({ downloadUrl });
    }

    if (!attachment.probativeAttachmentDownloadUrl) {
      let fileName = attachment.fileName.replace(RE_IMAGE, 'pdf');
      let probativeAttachmentDownloadUrl = `${API}/v3/attachments/${attachment.id}/download/${fileName}`;
      attachment.update({ probativeAttachmentDownloadUrl });
    }

    if (!attachment.probativeAttachmentFileName) {
      let probativeAttachmentFileName = attachment.fileName.replace(RE_IMAGE, 'pdf');
      attachment.update({ probativeAttachmentFileName });
    }

    if (!attachment.probativeAttachmentFileUrl) {
      let fileName = attachment.fileName.replace(RE_IMAGE, 'pdf');
      let probativeAttachmentFileUrl = `${API}/v3/attachments/${attachment.id}/file/${fileName}`;
      attachment.update({ probativeAttachmentFileUrl });
    }
  },
});
