import { Factory } from 'miragejs';

export default Factory.extend({
  name: i => `Insurance Contract ${i + 1}`,
  contractId: () => `TK${Math.floor(Math.random() * 100000000)}`,
  providerSlug: 'axa',
  type: () => {
    let types = ['public_liability', 'professional_liability', 'product_liability'];

    return types[Math.floor(Math.random() * types.length)];
  },
  status: () => {
    let statuses = ['active', 'pending_payment', 'pending_others', 'action_required', 'expired'];

    return statuses[Math.floor(Math.random() * statuses.length)];
  },
  troubleshootingUrl: 'https://example.com/troubleshooting',
  serviceUrl: 'https://example.com/service',
  expirationDate: () => new Date(Date.now() + 365 * 24 * 60 * 60 * 1000),
  startDate: () => new Date(),
  renewalDate: () => new Date(Date.now() + 330 * 24 * 60 * 60 * 1000),
  paymentFrequency: () => {
    let frequencies = ['month', 'quarter', 'annual'];

    return frequencies[Math.floor(Math.random() * frequencies.length)];
  },
  price: () => ({
    value: (Math.random() * 95 + 5).toFixed(2),
    currency: 'EUR',
  }),
  documents: () => {
    let numDocuments = Math.floor(Math.random() * 5) + 1;
    let documentTypes = ['contract', 'summary', 'terms', 'policy', 'addendum'];
    let documents = [];

    for (let i = 0; i < numDocuments; i++) {
      documents.push({
        id: crypto.randomUUID(),
        name: `Document ${i + 1}`,
        type: documentTypes[Math.floor(Math.random() * documentTypes.length)],
        url: `https://bucket.s3.amazonaws.com/documents/${crypto.randomUUID()}.pdf`,
      });
    }

    return documents;
  },
});
