import { Factory } from 'miragejs';

import { assert } from '@repo/qonto-mirage/utils/assert';

export default Factory.extend({
  name: i => `Beneficiary ${i + 1}`,
  country: 'US',
  currency: 'USD',
  paymentType: 'aba',
  accountIdentifier: '5060011118',
  bankIdentifier: '5050',

  afterCreate(model) {
    assert(
      model.organization,
      `Missing 'organization' relationship on 'international-out-beneficiary:${model.id}'`
    );
  },
});
