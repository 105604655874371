import { underscore } from '@repo/qonto-mirage/utils/string';

import ApplicationSerializer from './application';

export default ApplicationSerializer.extend({
  serialize() {
    let json = ApplicationSerializer.prototype.serialize.apply(this, arguments);

    if (json.price_plans) {
      for (let pricePlan of json.price_plans) {
        pricePlan.options = pricePlan.options.map(adjustOptionCasing);
      }
    }

    if (json.price_plan) {
      json.price_plan.options = json.price_plan.options.map(adjustOptionCasing);
    }

    return json;
  },
});

function adjustOptionCasing(option) {
  let newOption = {};
  for (let key of Object.keys(option)) {
    newOption[underscore(key)] = option[key];
  }
  return newOption;
}
