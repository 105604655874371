import { Factory, trait } from 'miragejs';

import { assert } from '@repo/qonto-mirage/utils/assert';

export default Factory.extend({
  id() {
    return this.organization?.id;
  },

  status: 'not_started',
  stepStatusNumbering: 'to_do',
  substepStatusInvoiceNumbering: 'to_do',
  stepStatusCompanyDetails: 'to_do',
  substepStatusCompanyDetails: 'to_do',
  substepStatusTermsAndConditions: 'to_do',
  stepStatusCustomization: 'to_do',
  substepStatusLogo: 'to_do',
  substepStatusColors: 'to_do',
  substepStatusCustomMessages: 'to_do',
  stepStatusAccountantAccess: 'to_do',
  prefilledAt: null,
  prefillData: {
    vat_number: null,
    commercial_register_number: null,
    legal_capital_share: null,
    invoice_numbering_pattern: null,
    invoice_next_number: null,
    discount_conditions: null,
    late_payment_penalties: null,
    legal_fixed_compensation: null,
    invoice_header: null,
    invoice_footer: null,
  },

  allStepsCompleted: trait({
    stepStatusNumbering: 'confirmed',
    substepStatusInvoiceNumbering: 'confirmed',
    stepStatusCompanyDetails: 'confirmed',
    substepStatusCompanyDetails: 'confirmed',
    substepStatusTermsAndConditions: 'confirmed',
    stepStatusCustomization: 'confirmed',
    substepStatusLogo: 'confirmed',
    substepStatusColors: 'confirmed',
    substepStatusCustomMessages: 'confirmed',
    stepStatusAccountantAccess: 'confirmed',
  }),

  allStepsPrefilled: trait({
    stepStatusNumbering: 'prefilled',
    substepStatusInvoiceNumbering: 'prefilled',
    stepStatusCompanyDetails: 'prefilled',
    substepStatusCompanyDetails: 'prefilled',
    substepStatusTermsAndConditions: 'prefilled',
    stepStatusCustomization: 'prefilled',
    substepStatusLogo: 'prefilled',
    substepStatusColors: 'prefilled',
    substepStatusCustomMessages: 'prefilled',
    stepStatusAccountantAccess: 'prefilled',
    prefilledAt: '2024-03-22T15:26:57.654Z',
    prefillData: {
      vat_number: 'FR12345678901',
      commercial_register_number: '123456789',
      legal_capital_share: '10000',
      invoice_numbering_pattern: 'INV-(YYYY)-',
      invoice_next_number: '1',
      discount_conditions: 'Discount conditions',
      late_payment_penalties: 'Late payment penalties',
      legal_fixed_compensation: 'Legal fixed compensation',
      invoice_header: 'Header',
      invoice_footer: 'Footer',
    },
  }),

  afterCreate(model) {
    assert(
      model.organization,
      `Missing 'organization' relationship on 'accounts-receivable-onboarding:${model.id}'`
    );
  },
});
