import { Factory } from 'miragejs';

import { dasherize } from '@repo/qonto-mirage/utils/string';

export default Factory.extend({
  amount: {
    currency: 'EUR',
    value: '45.80',
  },
  category: 'office_rental',
  counterpartyName: 'Dude and co.',
  emittedAt: '2018-05-10T13:45:46.689Z',
  localAmount: {
    currency: 'EUR',
    value: '45.80',
  },
  reference: 'for persian rug',
  settledAt: '2018-05-10T13:45:46.689Z',
  settledBalance: {
    currency: 'EUR',
    value: '45.80',
  },
  side: 'debit',
  status: 'completed',
  statusHistory: () => [],
  sequentialId: i => i + 1,
  enrichmentData: () => {},
  afterCreate(transaction) {
    if (!transaction.slug) {
      let orgSlug = transaction.initiator?.organization?.slug;
      let slug = (orgSlug || dasherize(transaction.reference)) + `-${transaction.sequentialId}`;
      transaction.update({ slug });
    }
  },
});
