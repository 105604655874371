import { pluralize } from 'ember-inflector';
import { JSONAPISerializer } from 'miragejs';

import { underscore } from '@repo/qonto-mirage/utils/string';

export default class RegistrationLegalEntitySerializer extends JSONAPISerializer {
  keyForAttribute(attr) {
    return underscore(attr);
  }

  typeKeyForModel({ modelName }) {
    if (modelName.startsWith('register-')) {
      modelName = modelName.slice(9);
    }

    return underscore(pluralize(modelName));
  }
}
