import { Factory } from 'miragejs';

import { assert } from '@repo/qonto-mirage/utils/assert';

export default Factory.extend({
  requestType: 'virtual_card',
  note: '',
  declinedNote: '',
  status: 'pending',
  paymentMonthlyLimit: null,
  currency: 'EUR',
  lastStep: true,
  processedAt: null,
  createdAt: null,

  afterCreate(request) {
    assert(
      request.organizationId,
      `Missing 'organization' relationship on 'request-virtual-card: ${request.id}'`
    );

    assert(
      request.paymentMonthlyLimit,
      `Missing 'paymentMonthlyLimit' on 'request-virtual-card: ${request.id}'`
    );
  },
});
