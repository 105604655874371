import { Factory } from 'miragejs';

import { BILLER } from '@repo/qonto-mirage/hosts';
import { assert } from '@repo/qonto-mirage/utils/assert';

export default Factory.extend({
  amount: {
    value: '118.04',
    currency: 'EUR',
  },
  vatAmount: {
    value: '23.62',
    currency: 'EUR',
  },
  totalAmount: {
    value: '141.66',
    currency: 'EUR',
  },
  fromDate: '2023-01-09',
  toDate: '2023-02-08',
  sequentialId: i => i + 1,

  afterCreate(invoice) {
    if (!invoice.slug) {
      let slug = `invoice-${invoice.sequentialId}`;
      invoice.update({ slug });
    }

    if (!invoice.pdfUrl) {
      let pdfUrl = `${BILLER}/v1/invoices/${invoice.id}/download.pdf`;
      invoice.update({ pdfUrl });
    }
    assert(invoice.organization, `Missing 'organization' relationship on 'invoice:${invoice.id}'`);
  },
});
