import { JSONAPISerializer } from 'miragejs';

import { underscore } from '@repo/qonto-mirage/utils/string';

export default class ReceivableInvoicesSettingsSerializer extends JSONAPISerializer {
  alwaysIncludeLinkageData = true;

  keyForAttribute(attr) {
    return underscore(attr);
  }

  serialize() {
    let { attributes, id } = super.serialize(...arguments).data;

    let { numbering_pattern: numberingPattern, next_number: nextNumber } = attributes;
    let month = (new Date().getMonth() + 1).toString().padStart(2, '0');
    let fullYear = new Date().getFullYear();

    if (numberingPattern) {
      attributes.next_invoice_number =
        numberingPattern
          .replace(/\(MM\)/g, month)
          .replace(/\(YYYY\)|\(AAAA\)|\(JJJJ\)/g, fullYear) + nextNumber;
    }

    attributes.credit_note_next_number_formatted = `${attributes.credit_note_numbering_pattern
      ?.replace(/\(MM\)/g, month)
      .replace(/\(YYYY\)|\(AAAA\)|\(JJJJ\)/g, fullYear)}${attributes.credit_note_next_number}`;
    attributes.invoice_next_number_formatted = `${attributes.invoice_numbering_pattern
      ?.replace(/\(MM\)/g, month)
      .replace(/\(YYYY\)|\(AAAA\)|\(JJJJ\)/g, fullYear)}${attributes.invoice_next_number}`;
    attributes.quote_next_number_formatted = `${attributes.quote_numbering_pattern
      ?.replace(/\(MM\)/g, month)
      .replace(/\(YYYY\)|\(AAAA\)|\(JJJJ\)/g, fullYear)}${attributes.quote_next_number}`;

    return {
      data: {
        id,
        type: 'settings',
        attributes,
      },
    };
  }
}
