import { Factory } from 'miragejs';

import { CLAIM_STATUSES, CLAIM_TYPES } from '@repo/qonto-mirage/constants/claim';
import { assert } from '@repo/qonto-mirage/utils/assert';

export default Factory.extend({
  status: CLAIM_STATUSES.review,
  transactionIds: [],
  type: null,

  afterCreate(model) {
    assert(
      Object.values(CLAIM_STATUSES).includes(model.status),
      `Invalid 'status' on 'claim:${model.id}'`
    );

    assert(model.transactionIds.length, `Empty 'transactionIds' on 'claim:${model.id}'`);

    assert(model.type, `Missing 'type' on 'claim:${model.id}'`);

    assert(
      Object.values(CLAIM_TYPES).includes(model.type),
      `Invalid 'type' on 'claim:${model.id}'`
    );
  },
});
