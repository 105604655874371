import { Factory } from 'miragejs';

import { assert } from '@repo/qonto-mirage/utils/assert';

export default Factory.extend({
  addressCity: 'Paris',
  addressCountry: 'FR',
  addressFirstLine: 'Rue La Fayette',
  addressSecondLine: '20B',
  addressThirdLine: 'Suite 10',
  addressZipCode: '75009',
  birthCity: 'Wintheiserland',
  birthCountry: 'SV',
  birthdate: '1974-02-26',
  corporateOfficer: false,
  firstName: 'John',
  gender: 0,
  lastName: 'Doe',
  legalRepresentative: false,
  role: 'owner',
  taxResidenceCountry: 'FR',
  taxResidenceIdentificationNumber: '88011876500010',
  ubo: false,
  us_tax_payer: false,
  tax_payer_outside_legal_country: false,

  afterCreate(membershipUpdate) {
    let modelName = 'kyc-kyb-membership-update';
    assert(
      membershipUpdate.kycKybUpdateProcess,
      `Missing 'kycKybUpdateProcess' relationship on '${modelName}:${membershipUpdate.id}'`
    );

    assert(
      membershipUpdate.membership,
      `Missing 'membership' relationship on '${modelName}:${membershipUpdate.id}'`
    );
  },
});
