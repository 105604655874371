import { Factory } from 'miragejs';

import { IBAN } from '@repo/qonto-mirage/utils/-ibankit';
import { assert } from '@repo/qonto-mirage/utils/assert';

export default Factory.extend({
  iban: () => IBAN.random('FR').value,

  afterCreate(model) {
    assert(model.membershipId, `Missing 'membership' relationship on 'bank-detail:${model.id}'`);
  },
});
