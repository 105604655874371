import { Factory } from 'miragejs';

import { assert } from '@repo/qonto-mirage/utils/assert';

export default Factory.extend({
  annualTurnover: '1000000-30000000',
  yearlyTransactionVolume: '50000-100000',
  maxSinglePaymentAmount: 10000,
  natureOfOperations: ['invoicing', 'spend_management'],

  afterCreate(model) {
    assert(
      model.organization,
      `Missing 'organization' relationship on 'organization-kyb-organization:${model.id}'`
    );
  },
});
