import { Factory } from 'miragejs';

import { assert } from '@repo/qonto-mirage/utils/assert';

export default Factory.extend({
  createdAt: '2021-03-28T14:02:25.654Z',
  iban: 'DE89 3704 0044 0532 0130 00',
  balance: '10000.32',
  balance_currency: 'EUR',
  status: 'submitted',
  updatedAt: '2021-03-28T14:02:25.654Z',

  afterCreate(model) {
    assert(
      model.shareholder,
      `Missing 'shareholder' relationship on 'cc-deposit-account:${model.id}'`
    );
  },
});
