import { Factory } from 'miragejs';

import { assert } from '@repo/qonto-mirage/utils/assert';

export default Factory.extend({
  status: 'created',
  legallyRequired: true,
  companyReportStatus: null, // 'found' | 'not_found' | 'unknown'
  createdAt: '2021-12-01T16:45:01Z',
  updatedAt: '2021-12-01T16:45:01Z',

  afterCreate(updateProcess) {
    assert(
      updateProcess.organization,
      `Missing 'organization' relationship on 'kyc-kyb-update-process:${updateProcess.id}'`
    );
  },
});
