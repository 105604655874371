import { Factory } from 'miragejs';

import { assert } from '@repo/qonto-mirage/utils/assert';

export default Factory.extend({
  last4: '3456',
  exp_date: '2024-01-31',
  type: 'card',

  afterCreate(paymentMethod) {
    assert(
      paymentMethod.organization,
      `Missing 'organization' relationship on 'paymentMethod:${paymentMethod.id}'`
    );
  },
});
