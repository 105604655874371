import ApplicationSerializer from './application';

export default ApplicationSerializer.extend({
  serialize() {
    let json = ApplicationSerializer.prototype.serialize.apply(this, arguments);
    delete json.subscription_product.bank_account_limit;
    delete json.subscription_product.receivable_invoice_global_limit;
    delete json.subscription_product.virtual_card_limit;
    delete json.subscription_product.accountant_limit;
    delete json.subscription_product.check_limit;
    delete json.subscription_product.deliveries_limit;
    delete json.subscription_product.physical_card_limit;
    delete json.subscription_product.sepa_out_limit;
    delete json.subscription_product.user_limit;

    return json;
  },
});
