import { COMPANYCREATION } from '@repo/qonto-mirage/hosts';
import { assert } from '@repo/qonto-mirage/utils/assert';
import { getSession } from '@repo/qonto-mirage/utils/session';

import ApplicationSerializer from './application';

const rolesCountingInPlans = ['active', 'invited', 'draft', 'pending'];

const COMPANY_CREATION_ATTRS = new Set([
  'id',
  'access_disabled',
  'address',
  'contact_email',
  'contract_status',
  'created_at',
  'deposit_capital_status',
  'draft',
  'kyb_status',
  'legal_country',
  'legal_form',
  'legal_name',
  'legal_number',
  'legal_registration_date',
  'legal_sector',
  'legal_share_capital',
  'legal_tva_number',
  'membership_ids',
  'name',
  'siren',
  'slug',
  'status',
  'under_registration',
]);

export default ApplicationSerializer.extend({
  include(request) {
    let includes = [];

    let includesQP = request.queryParams.includes || [];
    if (includesQP.includes('memberships')) {
      includes.push('memberships');
    }

    if (includesQP.includes('organization_kyb_details')) {
      includes.push('organizationKybDetails');
    }

    return includes;
  },

  serialize(organization, request) {
    let json = ApplicationSerializer.prototype.serialize.apply(this, arguments);

    let isCompanyCreation = request.url.toString().startsWith(COMPANYCREATION);

    let _with = request.queryParams.with || [];
    let withLabels = _with.includes('labels');
    let withLabelLists = _with.includes('label_lists');

    if (json.organization) {
      this._adjust(json.organization, {
        isCompanyCreation,
        withLabelLists,
        withLabels,
      });
    } else if (json.organizations) {
      for (let org of json.organizations) {
        this._adjust(org, {
          isCompanyCreation,
          withLabelLists,
          withLabels,
        });
      }
    }

    // We filter the memberships to the current user here so that the memberships_count and active_memberships_count
    // attributes are still calculated correctly as they should include ALL memberships.
    if (json.memberships) {
      let { session } = getSession(this.schema);
      json.memberships = json.memberships.filter(
        membership => membership.user_id === session.userId
      );
      let membershipIds = json.memberships.map(membership => membership.id);

      for (let organization of json.organizations) {
        organization.membership_ids = organization.membership_ids.filter(id =>
          membershipIds.includes(id)
        );
      }
    }

    return json;
  },

  _adjust(org, options) {
    let { schema } = this.registry;

    org.avatar_thumb = { file_url: org.avatar_thumb_url };
    org.avatar = { file_url: org.avatar_url };
    org.default_avatar_thumb = { file_url: org.default_avatar_thumb_url };
    org.default_avatar = { file_url: org.default_avatar_url };

    delete org.avatar_thumb_url;
    delete org.avatar_url;
    delete org.default_avatar_thumb_url;
    delete org.default_avatar_url;

    if (org.address_id) {
      let addressResource = schema.addresses.find(org.address_id);
      let [addressesHash] = this.getHashForIncludedResource(addressResource);
      org.address = addressesHash.addresses[0];
    }

    org.memberships_count = org.membership_ids.length;

    let activeMemberships = schema.memberships.where({ organizationId: org.id, status: 'active' });
    org.active_memberships_count = activeMemberships.length;

    // https://gitlab.qonto.co/qonto/qonto-api/-/blob/master/app/queries/membership_queries/accountants_counting_towards_plan_limit.rb
    let accountantsCountingTowardsPlanLimitCount = schema.memberships.where(
      ({ status, organizationId, role }) =>
        organizationId === org.id && rolesCountingInPlans.includes(status) && role === 'reporting'
    );

    org.accountants_counting_towards_plan_limit_count =
      accountantsCountingTowardsPlanLimitCount.length;

    // https://gitlab.qonto.co/qonto/qonto-api/-/blob/master/app/queries/membership_queries/memberships_counting_towards_plan_limit.rb
    let membershipsCountingTowardsPlanLimitCount = schema.memberships.where(
      ({ status, organizationId, role }) =>
        organizationId === org.id && rolesCountingInPlans.includes(status) && role !== 'reporting'
    );

    org.memberships_counting_towards_plan_limit_count =
      membershipsCountingTowardsPlanLimitCount.length;

    let requests = schema.requestTransfers
      .all()
      .filter(request => request.bankAccount.organizationId === org.id);
    org.requests_count = requests.length;

    let bankAccounts = schema.bankAccounts.where({ organizationId: org.id });

    let orgInMigration = (org.features || []).includes('migration_in_progress');
    // during migration we will need organizations without bank accounts
    // see tests/acceptance/migration/subscriptions-page-test.js (prepare function)
    if (bankAccounts.length === 0 && !orgInMigration) {
      let message = `\`bankAccounts\` relationship of \`organization:${org.id}\` (${org.name}) is empty`;
      assert(false, message);
    } else if (bankAccounts.length === 1) {
      org.core_banking_system = bankAccounts.models[0].coreBankingSystem;
    } else {
      org.core_banking_system = 'qonto';
    }

    if (options.withLabels || options.withLabelLists) {
      let labelLists = schema.labelLists.where({ organizationId: org.id });

      if (options.withLabelLists) {
        let [hash] = this.getHashForIncludedResource(labelLists);
        org.label_lists = hash.label_lists;
      }

      if (options.withLabels) {
        let allLabels = [];

        for (let labelList of labelLists.models) {
          let labels = schema.labels.where({ labelListId: labelList.id });

          let [hash] = this.getHashForIncludedResource(labels);
          allLabels = allLabels.concat(hash.labels);
        }

        org.labels = allLabels;
      }
    }

    let docs = schema.documents.where(({ id }) => org.document_ids.includes(id)).models;

    if (docs.length) {
      org.documents = docs.map(doc => {
        let result = {
          id: doc.id,
          subject_id: org.id,
          subject_type: 'Organization',
          status: doc.status,
          doc_type: doc.doc_type,
          slug: org.slug,
          created_at: doc.createdAt,
        };

        if (doc.files.length === 1) {
          result.file = doc.files[0];
        } else {
          result.files = doc.files;
        }

        return result;
      });
    }

    if (org.kyc_kyb_update_process_ids) {
      let ids = org.kyc_kyb_update_process_ids;
      let updateProcesses = schema.kycKybUpdateProcesses.where(({ id }) => ids.includes(id)).models;
      let ongoingUpdateProcess = updateProcesses.find(
        it => it.status === 'created' || it.status === 'pending_review'
      );
      org.ongoing_kyc_kyb_update_process_id = ongoingUpdateProcess ? ongoingUpdateProcess.id : null;
      delete org.kyc_kyb_update_process_ids;
    }

    // this property should not be serialized on the API
    delete org.under_incorporation;

    delete org.address_id;
    delete org.document_ids;
    delete org.label_ids;
    delete org.label_ids;
    delete org.label_list_ids;
    delete org.receivable_invoice_ids;
    delete org.stakeholder_ids;

    if (options.isCompanyCreation) {
      let keysToDelete = Object.keys(org).filter(key => !COMPANY_CREATION_ATTRS.has(key));
      for (let key of keysToDelete) {
        delete org[key];
      }
    }
  },
});
