import { Factory } from 'miragejs';

import { assert } from '@repo/qonto-mirage/utils/assert';

export default Factory.extend({
  status: 'eligible',
  collectionLimit: {
    value: '5000.00',
    currency: 'EUR',
  },
  hasGuarding: true,
  guardingDepositPercentage: '0.35',

  afterCreate(directDebitCollectionActivation) {
    assert(
      directDebitCollectionActivation.organization,
      `Missing 'organization' relationship on 'direct-debit-collection-activation:${directDebitCollectionActivation.id}'`
    );
  },
});
