import { pluralize } from 'ember-inflector';
import { JSONAPISerializer } from 'miragejs';

import { underscore } from '@repo/qonto-mirage/utils/string';

export default class DocumentCollectionProcessSerializer extends JSONAPISerializer {
  keyForAttribute(attr) {
    return underscore(attr);
  }

  keyForRelationship(key) {
    return underscore(pluralize(key));
  }

  typeKeyForModel({ modelName }) {
    if (modelName === 'required-document') {
      return 'document_collection_required_document';
    } else {
      return underscore(modelName);
    }
  }
}
