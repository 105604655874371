import { association, Factory } from 'miragejs';

import { assert } from '@repo/qonto-mirage/utils/assert';

export default Factory.extend({
  organization: association(),

  afterCreate(receivableCreditNote, server) {
    assert(
      receivableCreditNote.organization,
      `Missing 'organization' relationship on 'receivableCreditNote:${receivableCreditNote.id}'`
    );

    if (!receivableCreditNote.receivableInvoice) {
      receivableCreditNote.update({
        receivableInvoice: server.create('receivable-invoice', {
          organization: receivableCreditNote.organization,
        }),
      });
    }

    if (!receivableCreditNote.attachment) {
      receivableCreditNote.update({
        attachment: server.create('attachment', {
          organization: receivableCreditNote.organization,
        }),
      });
    }
  },
});
