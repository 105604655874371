import { Factory } from 'miragejs';

import { RECURRING_TYPES, STATUS } from '@repo/qonto-mirage/constants/direct-debit-collections';

export default Factory.extend({
  debitorName: i => `Debitor Name ${i + 1}`,
  reference: i => `Reference ${i + 1}`,
  uniqueMandateReference: i => `mandate${i + 1}`,
  status: STATUS.COMPLETED,
  recurenceType: RECURRING_TYPES.ONE_OFF,
  activityTag: 'other_income',
  amount: {
    value: '15.23',
    currency: 'EUR',
  },
  debitorIban: 'FR76300010079412effez23424',
  debitorBic: 'GT7542',
  executedAt: null,
  collectionDate: null,
  type: 'core',
});
