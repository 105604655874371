import { Factory } from 'miragejs';

import { assert } from '@repo/qonto-mirage/utils/assert';

export default Factory.extend({
  requestType: 'multi_direct_debit_collection',
  note: null,
  status: null,
  operationType: null,
  totalAmount: {
    value: '2331.24',
    currency: 'EUR',
  },
  totalCount: null,
  createdAt: '2023-11-17T00:00:00.000Z',
  processedAt: null,
  lastStep: true,

  afterCreate(request) {
    assert(
      request.initiator || request.initiatorId,
      `Missing 'initiator' (membership) relationship on 'request-multi-direct-debit-collection:${request.id}'`
    );

    assert(
      request.bankAccount || request.bankAccountId,
      `Missing 'bankAccount' relationship on 'request-multi-direct-debit-collection:${request.id}'`
    );

    if (!request.totalCount) {
      request.update({
        totalCount: request.requestDirectDebitCollections.length,
      });
    }
  },
});
