import { Factory } from 'miragejs';

import { assert } from '@repo/qonto-mirage/utils/assert';

/**
 * https://openapi-master.staging.qonto-cbs.co/partner-register#operation/registrations/get
 */
export default Factory.extend({
  flowUserType: 'owner',
  ownerEmail: 'jim.morrison@qonto.com',
  partnerName: 'Legalstart',
  ownerFirstName: 'Jim',
  organizationName: 'The Doors inc',
  legalFlow: 'company_creation',
  organizationLegalCountry: 'FR',
  flowType: 'seamless',
  partnerType: 'legal_tech',
  companyCreationFromScratch: false,

  afterCreate(model) {
    assert(
      model.registration,
      `Missing 'registration' relationship on 'redirection-token:${model.id}'`
    );
  },
});
