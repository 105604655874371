import { Factory } from 'miragejs';

import { assert } from '@repo/qonto-mirage/utils/assert';

export default Factory.extend({
  amount: 42.31,
  amountCurrency: 'EUR',
  counterpartyName: 'Gleichner and Sons',
  description: 'Dare, Greenfelder and Parker',
  emittedAt: '2018-06-17T07:38:54.000Z',
  sequentialId: i => i + 1,
  settledAt: '2018-05-10T13:45:46.689Z',
  status: 'completed',

  afterCreate(transaction) {
    assert(
      transaction.bankAccount,
      `Missing 'bankAccount' relationship on 'transaction:${transaction.id}'`
    );

    if (transaction.bankAccount?.coreBankingSystem === 'connect') {
      if (transaction.operationMethod === 'card')
        transaction.update({
          counterparty: null,
        });
      else {
        transaction.update({
          counterparty: {
            iban: 'FR7630004021180001013711792',
            bic: 'BNPAFRPPXXX',
            account_number: 'ABC1234',
          },
        });
      }

      transaction.update({ activityTag: 'finance', side: 'debit' });
    } else {
      if ('initiator' in transaction.subject) {
        transaction.update({ initiator: transaction.subject.initiator });
      }
      if (!transaction.operationMethod) {
        let operationMethod;
        let subjectType = transaction.subject.modelName;
        if (
          subjectType === 'transfer' ||
          subjectType === 'income' ||
          subjectType === 'swift-income'
        ) {
          operationMethod = 'transfer';
        } else if (subjectType === 'card') {
          operationMethod = 'card';
        } else if (subjectType === 'check') {
          operationMethod = 'cheque';
        } else if (subjectType === 'direct-debit' || subjectType === 'direct-debit-collection') {
          operationMethod = 'direct_debit';
        } else if (subjectType === 'wallet-to-wallet') {
          operationMethod = 'biller';
        } else if (subjectType === 'pagopa-payment') {
          operationMethod = 'pagopa_payment';
        } else if (subjectType === 'f24-payment') {
          operationMethod = 'tax';
        } else if (subjectType === 'financing-income' || subjectType === 'financing-installment') {
          operationMethod = 'pay_later';
        } else {
          assert(false, `Unknown subject type on \`transaction:${transaction.id}\``);
        }
        transaction.update({ operationMethod });
      }

      // Using hasOwnProperty allows to set it as `null` or `undefined`
      if (!transaction.hasOwnProperty('activityTag')) {
        let activityTag;
        let subjectType = transaction.subject.modelName;
        if (subjectType === 'pagopa-payment') {
          activityTag = 'other_expense';
        } else if (subjectType === 'f24-payment') {
          activityTag = 'tax';
        } else {
          activityTag = 'finance';
        }
        transaction.update({ activityTag });
      }
    }
  },
});
