import { API } from '@repo/qonto-mirage/hosts';
import { IBAN } from '@repo/qonto-mirage/utils/-ibankit';
import { getSession } from '@repo/qonto-mirage/utils/session';

import ApplicationSerializer from './application';

const bankAccountsKeysToDelete = {
  employee: [
    'balance',
    'balance_cents',
    'authorized_balance',
    'authorized_balance_cents',
    'processing_balance',
    'processing_balance_cents',
    'balance_currency',
    'iban_pdf_url',
    'iban',
    'bic',
    'country_code',
    'bank_code',
    'branch_code',
    'check_digits',
    'account_number',
    'control_digits',
    'account_type',
  ],
  teamManagerWithPermission: [
    'iban_pdf_url',
    'iban',
    'bic',
    'country_code',
    'bank_code',
    'branch_code',
    'check_digits',
    'account_number',
    'control_digits',
  ],
  managerWithoutPermissions: [
    'balance',
    'balance_cents',
    'authorized_balance',
    'authorized_balance_cents',
    'processing_balance',
    'processing_balance_cents',
    'balance_currency',
    'iban_pdf_url',
    'iban',
    'bic',
    'country_code',
    'bank_code',
    'branch_code',
    'check_digits',
    'account_number',
    'control_digits',
    'account_type',
  ],
};

export default ApplicationSerializer.extend({
  serialize() {
    let json = ApplicationSerializer.prototype.serialize.apply(this, arguments);

    let url = this.request.url.toString();
    let namespace = url.substring(API.length + 1).match(/^(v\d+)/)[0];

    if (json.bank_account) {
      this._adjust(json.bank_account, namespace);
    } else if (json.bank_accounts) {
      for (let bankAccount of json.bank_accounts) {
        this._adjust(bankAccount, namespace);
      }
    }

    return json;
  },

  _adjust(bankAccount, namespace) {
    delete bankAccount.sequential_id;

    let { session } = getSession(this.schema);
    let organizationId = bankAccount.organization_id;
    let membership = this.schema.memberships.findBy({ userId: session.userId, organizationId });
    let membershipRole = this.schema.roles.findBy({ membershipId: membership.id });

    let customPermissions = membershipRole?.customPermissions;
    let keysToDelete = [];

    switch (namespace) {
      case 'v5':
        switch (membership.role) {
          case 'employee':
            keysToDelete = bankAccountsKeysToDelete.employee;
            break;
          case 'manager':
            switch (customPermissions?.scope) {
              case 'organization':
                if (!customPermissions?.groups?.access_bank_accounts_statements) {
                  keysToDelete = bankAccountsKeysToDelete.managerWithoutPermissions;
                }
                break;
              case 'team':
                if (customPermissions?.groups?.access_bank_accounts_balance) {
                  keysToDelete = bankAccountsKeysToDelete.teamManagerWithPermission;
                } else {
                  keysToDelete = bankAccountsKeysToDelete.managerWithoutPermissions;
                }
                break;
            }
            break;
        }
        for (let key of keysToDelete) delete bankAccount[key];
        break;
      default:
        if (membership.role === 'manager') {
          keysToDelete = bankAccountsKeysToDelete.employee;
          for (let key of keysToDelete) delete bankAccount[key];
        }
        break;
    }

    if (bankAccount.iban) {
      let iban = new IBAN(bankAccount.iban);

      bankAccount.country_code = iban.getCountryCode();
      bankAccount.bank_code = iban.getBankCode();
      bankAccount.branch_code = iban.getBranchCode();
      bankAccount.check_digits = iban.getNationalCheckDigit();
      bankAccount.account_number = iban.getAccountNumber();
      bankAccount.control_digits = iban.getCheckDigit();
    }
  },
});
