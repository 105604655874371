import { belongsTo, hasMany, Model } from 'miragejs';

/**
 * @see http://qonto.pages.qonto.co/qonto-register/v1/#the-organization-object
 */
export default Model.extend({
  // TODO depositPayment: belongsTo(),
  documents: hasMany(),
  stakeholders: hasMany(),
  user: belongsTo(),
  owner: belongsTo('stakeholder', { inverse: null }),
  legalEntities: hasMany('register-legal-entity'),
  partnerRegistration: belongsTo(),
  creationRedirection: belongsTo(),
});
