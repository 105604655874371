import * as React from 'react';
import { clsx } from 'clsx';
import sharedStyles from './shared-groups.strict-module.css';
import styles from './benefits-group.strict-module.css';
import { TextWithHighlights } from './text-with-highlights';

interface BenefitsGroupProps {
  title: string;
  benefits: { description: string; isIncluded: boolean }[];
}

export function BenefitsGroup({ title, benefits }: BenefitsGroupProps): React.ReactNode {
  return (
    <div data-test-benefit-group={title}>
      <h3 className={clsx('title-4', 'mb-8', sharedStyles.title)} data-test-benefit-title>
        {title}
      </h3>
      <ul className={sharedStyles.list}>
        {benefits.map(({ description, isIncluded }, index) => (
          <li
            className={clsx(
              sharedStyles['list-item'],
              styles['list-item'],
              isIncluded ? 'body-1' : 'body-2',
              isIncluded ? styles.included : styles.excluded,
              'mb-8'
            )}
            data-test-benefit-description={index}
            key={description}
          >
            <div aria-hidden="true" className={clsx('mr-16', sharedStyles.image, styles.image)} />
            <TextWithHighlights>{description}</TextWithHighlights>
          </li>
        ))}
      </ul>
    </div>
  );
}
