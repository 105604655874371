import { pluralize } from 'ember-inflector';
import { JSONAPISerializer } from 'miragejs';

import { underscore } from '@repo/qonto-mirage/utils/string';

export default class EinvoicingSettingSerializer extends JSONAPISerializer {
  keyForAttribute(attr) {
    return underscore(attr);
  }

  typeKeyForModel({ modelName }) {
    if (modelName === 'einvoicing-setting') {
      return 'settings';
    }

    return underscore(pluralize(modelName));
  }
}
