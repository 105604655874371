import { pluralize } from 'ember-inflector';
import { JSONAPISerializer } from 'miragejs';

import { underscore } from '@repo/qonto-mirage/utils/string';

export default JSONAPISerializer.extend({
  alwaysIncludeLinkageData: true,

  keyForAttribute(attr) {
    // use snake_case for attribute names
    return underscore(attr);
  },

  keyForRelationship(key) {
    // use snake_case for relationship names
    return underscore(key);
  },

  typeKeyForModel({ modelName }) {
    return underscore(pluralize(modelName));
  },
});
