import { Factory } from 'miragejs';

import {
  APPROVAL_WORKFLOW_STEP_OPERATIONS,
  APPROVAL_WORKFLOW_STEP_STATES,
} from '@repo/qonto-mirage/constants/approval-workflows';
import { assert } from '@repo/qonto-mirage/utils/assert';

export default Factory.extend({
  status: 'approved',
  operation: 'one_of',
  value: ['123e4567-e89b-12d3-a456-426614174000', '987e6543-e21b-34d3-a456-426614174111'],
  approvers: ['123e4567-e89b-12d3-a456-426614174000'],

  afterCreate(step) {
    assert(step.status, `Missing 'status' on 'approval-workflow-state-step'`);
    assert(
      Object.values(APPROVAL_WORKFLOW_STEP_STATES).includes(step.status),
      `Incorret 'status' on 'approval-workflow-state-step'`
    );

    assert(step.operation, `Missing 'operation' on 'approval-workflow-state-step'`);
    assert(
      Object.values(APPROVAL_WORKFLOW_STEP_OPERATIONS).includes(step.operation),
      `Incorret 'operation' on 'approval-workflow-state-step'`
    );

    assert(step.value?.length > 0, `Missing 'value' on 'approval-workflow-state-step'`);
  },
});
