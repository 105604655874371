import { Factory } from 'miragejs';

import { assert } from '@repo/qonto-mirage/utils/assert';

/**
 * https://openapi-master.staging.qonto-cbs.co/partner-register-client-api#operation/payment-intents/post
 */
export default Factory.extend({
  clientSecret: 'my_client_secret',
  status: 'requires_action',
  pricePlanId: i => crypto.randomUUID().toString().padStart(1, i),
  paymentMethodId: i => crypto.randomUUID().toString().padStart(1, i),
  requiresAction: true,

  afterCreate(model) {
    assert(
      model.registration,
      `Missing 'registration' relationship on 'payment-intent:${model.id}'`
    );
  },
});
