import { Factory } from 'miragejs';

import { assert } from '@repo/qonto-mirage/utils/assert';

export default Factory.extend({
  sendTo: ['user@example.com'],
  copyToSelf: false,

  afterCreate(receivableInvoicesReminder) {
    assert(
      receivableInvoicesReminder.organization,
      `Missing 'organization' relationship on 'receivableInvoicesReminder:${receivableInvoicesReminder.id}'`
    );

    assert(
      receivableInvoicesReminder.clientHub,
      `Missing 'clientHub' relationship on 'receivableInvoicesReminder:${receivableInvoicesReminder.id}'`
    );
  },
});
