import { Factory } from 'miragejs';

import { assert } from '@repo/qonto-mirage/utils/assert';

export default Factory.extend({
  legalName: i => `Tyrell Corporation ${i}`,
  legalNumber: i => `8194896260001${i}`,
  capitalAmount: null,
  createdAt: '2021-03-22T15:26:57.654Z',

  afterCreate(model) {
    assert(model.organization, `Missing 'organization' relationship on 'legal-entity:${model.id}'`);
    assert(model.stakeholder, `Missing 'stakeholder' relationship on 'legal-entity:${model.id}`);
  },
});
