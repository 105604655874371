import { Factory } from 'miragejs';

import { assert } from '@repo/qonto-mirage/utils/assert';

export default Factory.extend({
  createdAt: '2021-08-17T12:31:32.689Z',
  filename: 'document.pdf',
  filesizeBytes: i => 12345 + 324 * i,
  updatedAt: '2021-09-01T18:19:01.810Z',
  // `url` is generated in the serializer from `id` and `filename`

  afterCreate(model) {
    assert(model.user, `Missing 'user' relationship on 'cc-file:${model.id}'`);
  },
});
