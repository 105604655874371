import { Factory, trait } from 'miragejs';

import { assert } from '@repo/qonto-mirage/utils/assert';

export default Factory.extend({
  addressCity: 'Paris 5e Arrondissement',
  addressCountry: 'FR',
  addressLine1: '18 Rue Basse des Carmes',
  addressLine2: null,
  addressZipcode: '75005',
  birthCity: 'Paris',
  birthCountry: 'FR',
  birthZipcode: '12345',
  birthdate: '1991-10-10',
  capitalAmountCents: null,
  corporateOfficer: false,
  createdAt: '2019-10-10T08:45:33.861Z',
  email: i => `stakeholder${i + 1}@qonto.eu`,
  firstName: 'Steak',
  kycProvider: 'onfido',
  gavePower: false,
  gender: 'male',
  humanizedTitle: 'Legal Representative',
  italianFiscalCode: null,
  lastName: i => `Holder ${i + 1}`,
  locale: null,
  nationality: 'FR',
  onfidoToken: () => buildOnfidoToken(),
  phoneNumber: '+33123456789',
  powered: null,
  role: 'owner',
  shareholder: true,
  timezone: null,
  title: 'legal_representative',
  ubo: true,
  usTaxPayer: null, // A boolean flag (initially will be null to mimic the behavior on the BE)
  taxPayerOutsideLegalCountry: null, // A boolean flag (initially will be null to mimic the behavior on the BE)

  empty: trait({
    addressCity: null,
    addressCountry: null,
    addressLine1: null,
    addressLine2: null,
    addressZipcode: null,
    birthCity: null,
    birthCountry: null,
    birthZipcode: null,
    birthdate: null,
    email: null,
    firstName: null,
    gender: null,
    humanizedTitle: null,
    lastName: null,
    nationality: null,
    phoneNumber: null,
    role: null,
    timezone: null,
    title: null,
  }),

  afterCreate(model) {
    assert(model.organization, `Missing 'organization' relationship on 'stakeholder:${model.id}'`);

    if (model.role === 'owner') {
      model.organization.update({ owner: model });
    }
  },
});

function buildOnfidoToken() {
  // We return a hard coded json web token with a expiration date set to 2100-12-31, rather than creating it via jsonwebtoken.
  // Having this library installed prevents the Onfido SDK to load in dev mode, for a reason that has to be determined.
  // You can see this token's details at
  // https://jwt.io/#debugger-io?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwYXlsb2FkIjoicGF5bG9hZCIsInV1aWQiOiJmWnJMSTQwWFBhSCIsImV4cCI6NDEzMzg5MDgwMCwidXJscyI6eyJvbmZpZG9fYXBpX3VybCI6Imh0dHBzOi8vYXBpLm9uZmlkby5jb20iLCJ0ZWxlcGhvbnlfdXJsIjoiaHR0cHM6Ly90ZWxlcGhvbnkub25maWRvLmNvbSIsImRldGVjdF9kb2N1bWVudF91cmwiOiJodHRwczovL3Nkay5vbmZpZG8uY29tIiwic3luY191cmwiOiJodHRwczovL3N5bmMub25maWRvLmNvbSIsImhvc3RlZF9zZGtfdXJsIjoiaHR0cHM6Ly9pZC5vbmZpZG8uY29tIn0sImlhdCI6MTU5MTc3NzY3NH0.k_nuMQBCRqlsTKxQftSkPsw_X8Nram5RttSPLeYzNfI
  return 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwYXlsb2FkIjoicGF5bG9hZCIsInV1aWQiOiJmWnJMSTQwWFBhSCIsImV4cCI6NDEzMzg5MDgwMCwidXJscyI6eyJvbmZpZG9fYXBpX3VybCI6Imh0dHBzOi8vYXBpLm9uZmlkby5jb20iLCJ0ZWxlcGhvbnlfdXJsIjoiaHR0cHM6Ly90ZWxlcGhvbnkub25maWRvLmNvbSIsImRldGVjdF9kb2N1bWVudF91cmwiOiJodHRwczovL3Nkay5vbmZpZG8uY29tIiwic3luY191cmwiOiJodHRwczovL3N5bmMub25maWRvLmNvbSIsImhvc3RlZF9zZGtfdXJsIjoiaHR0cHM6Ly9pZC5vbmZpZG8uY29tIn0sImlhdCI6MTU5MTc3NzY3NH0.k_nuMQBCRqlsTKxQftSkPsw_X8Nram5RttSPLeYzNfI';
}
