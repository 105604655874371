import { Factory } from 'miragejs';

import { assert } from '@repo/qonto-mirage/utils/assert';
export default Factory.extend({
  current_maturity: 0,
  max_maturity: 180,
  start_date: null,
  end_date: null,
  is_tax_applicable: null,
  max_maturity_earnings: null,
  interest_rates: [
    {
      start_day: 0,
      end_day: 29,
      rate: '0',
    },
    {
      start_day: 30,
      end_day: 59,
      rate: '0.25',
    },
    {
      start_day: 60,
      end_day: 89,
      rate: '0.25',
    },
    {
      start_day: 90,
      end_day: 119,
      rate: '0.25',
    },
    {
      start_day: 120,
      end_day: 149,
      rate: '1.5',
    },
    {
      start_day: 150,
      end_day: 179,
      rate: '1.7',
    },
    {
      start_day: 180,
      end_day: 180,
      rate: '2.45',
    },
  ],
  afterCreate(model) {
    assert(
      model.bankAccount,
      `Missing 'bankAccount' relationship on 'RemuneratedDetail:${model.id}'`
    );
    assert(
      model.remuneratedAccount,
      `Missing 'remuneratedAccount' relationship on 'RemuneratedDetail:${model.id}'`
    );
  },
});
