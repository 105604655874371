import { Factory } from 'miragejs';

import { assert } from '@repo/qonto-mirage/utils/assert';

export default Factory.extend({
  key: null,
  value: null,

  afterCreate(model) {
    assert(model.key, `Missing 'key' on 'mirage-config:${model.id}'`);
  },
});
