import { Factory } from 'miragejs';

export default Factory.extend({
  amount: {
    value: 0,
    currency: 'EUR',
  },
  iban: '',
  note: '',
  declinedNote: '',
  reference: 'mileage',
  status: 'pending',
  createdAt: '2019-11-28T10:12:56.679Z',
  processedAt: null,
  initiatorId: null,
  organizationId: null,
  requestType: 'mileage',
  departure: {
    formatted_address: '1 rue de Lille, 75009 Paris',
    google_place_id: 'FREUD456HDF',
  },
  arrival: {
    formatted_address: '1 rue de Lille, 75009 Paris',
    google_place_id: 'FREUD456HDF',
  },
  roundTrip: true,
  distance_meters: 1042,
  mileageCalculation: {
    rate: {
      value: '0.519',
      unit: 'EUR/KM',
    },
    distance_meters: 1042,
  },
});
